import React, { useState, useEffect, useCallback } from "react";
import { useUserContext } from "src/shared/hooks";
import api from "src/api/helperPromise";
import { toast } from "react-toastify";
import { notSigned } from "src/shared/constants/contractStatuses";

import Chart from "../Chart/Chart";
import DashboardStats from "../DashboardStats/DashboardStats";
import { Link } from "react-router-dom";

import { DashboardTile, TileLink, TileName, TileAmount, TileText } from "./DashboardTile";

function Dashboard() {
  // context
  const { user } = useUserContext();

  //states
  const defaultTransactionSummary = {
    amountTotal: 0,
    startDate: new Date(),
    transactionCount: 0,
    transactionType: "",
  };
  const [tipsTotal, setTipsTotal] = useState(defaultTransactionSummary);
  const [tipsTax, setTipsTax] = useState(defaultTransactionSummary);
  const [tipsProcessing, setTipsProcessing] = useState(defaultTransactionSummary);
  const [tipsTransfered, setTipsTransfered] = useState(defaultTransactionSummary);
  const [tipsCount, setTipsCount] = useState(0);
  const [contractSigned, setcontractSigned] = useState(true);

  //return;
  const sessionId = user?.session?.sessionId;

  // console.log(user.subject.subject.privateEntity );

  const labelFirstName = user.subject.subject?.privateEntity?.firstName;
  const labelLastName = user.subject.subject?.privateEntity?.lastName;
  const labelCard = user.subject.card?.pan;

  const financeTransactionSummary = () => {
    console.log("financeTransactionSummary start");

    const body = {
      //      authType: authType,
      //      authData: authData,
    };

    api
      .financeTransactionSummary(sessionId, body)
      .finally(() => console.log("apiFinanceTransactionSummary Promise is ready"))
      .then((data) => {
        console.log("apiFinanceTransactionSummary data", data);

        if (data.transactionsSummary && Array.isArray(data.transactionsSummary)) {
          data.transactionsSummary.forEach((obj) => {
            if (obj.transactionType == "TIPS") setTipsTotal(obj);
            if (obj.transactionType == "GPM") setTipsTax(obj);
            if (obj.transactionType == "TIPSPRC") setTipsProcessing(obj);
            if (obj.transactionType == "TIPSTRANSFER") setTipsTransfered(obj);

            setTipsCount(obj.transactionCount);
          });
        }

        // TODO
        //toast.success("apiFinanceTransactionSummary");
      })
      .catch((err) => {
        console.warn("apiFinanceTransactionSummary", err);
        //toast.error("err");
      });
  };

  const getContractSignInformation = useCallback(async () => {
    if (!sessionId || !user?.subject?.subject) {
      return;
    }

    const contract = await api.contractContract(
      sessionId,
      user.subject.subject.subjectId
    );

    console.log(contract);
    if (!contract || !contract.document) {
      return;
    }

    if (contract.document.contractStatus === notSigned) {
      setcontractSigned(false);
      toast.warning("Sutartis nepasirašyta, prašau pasirašyti");
    }
  }, [user?.subject?.subject, sessionId]);

  useEffect(() => {
    financeTransactionSummary();
    getContractSignInformation();
  }, []); // eslint-disable-line

  // chech if user
  if (!user || !user.subject) return;

  return (
    <div className="content">
      <div className="container">
        <div className="row">
          <DashboardTile icon="far fa-id-card">
            <TileName>{labelFirstName}</TileName>
            <TileText>{labelLastName}</TileText>
            <TileLink
              link="/Profile"
              text="Redaguoti anketą"
              showTooltip={!contractSigned}
              tooltipTitle="Sutartis nepasirašyta"
            />
          </DashboardTile>

          <DashboardTile icon="far fa-credit-card">
            <TileName>
              <strong>my tips</strong> kortelė
            </TileName>
            <TileText>
              {labelCard ? (
                labelCard
              ) : (
                <Link to="/Profile" className="text-red">
                  Pridėti kortelę
                </Link>
              )}
            </TileText>
            <div className="row">
              <div className="col-md-6">
                <TileLink link="/Cards" text="Mano kortelės" />
              </div>
              {false && (
                <div className="col-md-6 text-right">
                  <a href="#">
                    <small>
                      Pridėti kortelę <i className="fas fa-plus"></i>
                    </small>
                  </a>
                </div>
              )}
            </div>
          </DashboardTile>

          <DashboardTile icon="far fa-bell">
            <TileName hint="Atskaičius GPM, paslaugos ir pavedimo mokesčius">
              Pervesti arbatpinigiai
            </TileName>
            <TileAmount
              amount={tipsTransfered.amountTotal}
              date={tipsTransfered.startDate}
            />
            <TileLink link="/Stats" text="Detali ataskaita" />
          </DashboardTile>
        </div>

        <div className="row">
          <DashboardTile icon="far fa-credit-card">
            <TileName>Klientų palikti arbatpinigiai</TileName>
            <TileAmount amount={tipsTotal.amountTotal} date={tipsTotal.startDate} />
          </DashboardTile>

          <DashboardTile icon="far fa-thumbs-up">
            <TileName>Sumokėtas GPM</TileName>
            <TileAmount amount={tipsTax.amountTotal} date={tipsTax.startDate} />
          </DashboardTile>

          <DashboardTile icon="far fa-thumbs-up">
            <TileName hint="Klientų palikti arbatpinigiai, kuriuos dar neapdorojo bankas ir my tips sistema. Arbatpingiai bus pervesti į paslaugos gavėjo sąskaitą, jeigu ji teisingai nurodyta anketoje.">
              Apdorojami arbatpinigiai
            </TileName>
            <TileAmount
              amount={tipsProcessing.amountTotal}
              date={tipsProcessing.startDate}
            />
          </DashboardTile>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <Chart />
          </div>

          <div className="col-lg-6">
            <div className="card">
              <div className="card-header border-0">
                <div className="d-flex justify-content-between">
                  <h3 className="card-title">Paskutiniai aptarnavimai</h3>
                </div>
              </div>

              <DashboardStats />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
