import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useUserContext } from "src/shared/hooks";
import api from "../../api/helperPromise";
import { format } from "date-fns";
import { triggerBase64Download } from "common-base64-downloader-react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import lt from "date-fns/locale/lt";

import DataTable from "react-data-table-component";
import { ShowButtonBlock } from "../base/ShowButtonBlock";

registerLocale("lt", lt);

const CustomCell = ({ row }) => {
  let myRow = row.categoryName.split("\\n\\r");

  return (
    <div>
      {myRow[0]}
      <br />
      <strong>{myRow[1]}</strong>
    </div>
  );
};

const columnsAction = [
  {
    name: "#",
    selector: (row) => row.id,
    sortable: true,
    compact: true,
    minWidth: "2%",
    maxWidth: "4%",
  },
  {
    name: "Data",
    selector: (row) => row.date,
    sortable: true,
    maxWidth: "200px",
  },
  {
    name: "Įvykio pavadinimas",
    selector: (row) => row.name,
    sortable: true,
    maxWidth: "20%",
  },
  {
    name: "Įvykio aprašymas",
    selector: (row) => row.categoryName,
    sortable: true,
    wrap: true,
    cell: (row) => <CustomCell row={row} />,
    maxWidth: "50%",
  },
];

const columns = [
  {
    name: "#",
    selector: (row) => row.id,
    sortable: true,
    compact: true,
    minWidth: "2%",
    maxWidth: "4%",
  },
  {
    name: "Prekybininkas",
    selector: (row) => row.debitorAccount,
    sortable: true,
  },
  {
    name: "Transakcijos tipas",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Data",
    selector: (row) => row.transactionValueDate,
    sortable: true,
    maxWidth: "100px",
  },
  {
    name: "Suma",
    selector: (row) => row.amount,
    sortable: true,
    right: true,
    maxWidth: "100px",
  },
];
const paginationComponentOptions = {
  rowsPerPageText: "Puslapio eilės",
  rangeSeparatorText: "iš",
};

function SubjectStats() {
  // state
  const [dataActions, setDataActions] = useState(false);
  const [dataItems, setDataItems] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [subject, setSubject] = useState("");
  const [inputCode, setInputCode] = useState("");
  const [selectValue, setSelectValue] = useState("PHONE");

  // context
  const { user } = useUserContext();

  // chech if user
  if (!user || !user.auth?.code) return;

  //return;
  const sessionId = user?.session?.sessionId;

  const getSubject = () => {
    console.log("subjectGetSubject start");

    // FIX get string plus sign
    let inputCodeGetString = inputCode.replace("+", "%2B");

    api
      .subjectGetSubject(sessionId, inputCodeGetString, selectValue)
      .finally(() => console.log("subjectGetSubject Promise is ready"))
      .then((data) => {
        console.log("subjectGetSubject data", data);

        if (data.subject) {
          setSubject(data);

          // reset tables
          setDataActions({});
          setDataItems({});
        } else {
          setSubject(null);
        }
      })
      .catch((err) => {
        console.warn("subjectGetSubject", err);
      });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    getSubject();
  };

  const monitoringUserActions = () => {
    console.log("monitoringUserActions start");

    var startDateFormated = format(startDate, "yyyy-MM-dd");
    var endDateFormated = format(endDate, "yyyy-MM-dd");

    const code = subject?.subject?.code;

    const body = {
      // TODO
      dateFrom: startDateFormated,
      dateTo: endDateFormated,
      personalCode: code,
    };
    console.log(body);

    api
      .monitoringUserActions(sessionId, body)
      .finally(() => console.log("monitoringUserActions Promise is ready"))
      .then((data) => {
        console.log("monitoringUserActions data", data);

        var newData = [];

        data.userActions.map((item, i) => {
          //console.log(item, i)

          var date = new Date(item.date);

          const tempData = {
            id: i + 1,
            categoryCode: item.categoryCode,
            categoryName: item.categoryName,
            date: format(date, "yyyy-MM-dd HH:mm:ss"),
            name: item.name,
          };

          //console.log(tempData);
          newData.push(tempData);
        });

        setDataActions(newData);
      })
      .catch((err) => {
        console.log("monitoringUserActions", err);
      });
  };

  const financeTransaction = () => {
    console.log("financeTransaction start - subjectStats.jsx");

    var startDateFormated = format(startDate, "yyyy-MM-dd");
    var endDateFormated = format(endDate, "yyyy-MM-dd");

    const creditor = {
      subjectId: subject.subject?.subjectId,
    };

    const body = {
      transactionDate: startDateFormated,
      transactionDateTo: endDateFormated,
      creditor: creditor,
    };

    api
      .financeTransaction(sessionId, body)
      .finally(() => console.log("financeTransaction Promise is ready"))
      .then((data) => {
        console.log("financeTransaction data", data);

        var newData = [];

        data.transactions.map((item, i) => {
          //console.log(item, i)

          var date = new Date(item.transactionValueDate);

          const tempData = {
            id: i + 1,
            debitorAccount: item.debitorAccount.subjectName,
            amount: item.amount.amount + " " + item.amount.currency,
            transactionValueDate: format(date, "yyyy-MM-dd"),
            name: item.name,
          };

          //console.log(tempData);
          newData.push(tempData);
        });

        setDataItems(newData);
      })
      .catch((err) => {
        console.log("financeTransaction", err);
      });
  };

  const onDateSubmit = (event) => {
    event.preventDefault();

    monitoringUserActions();
    financeTransaction();
  };

  const onChangeValue = (event) => {
    setSelectValue(event.target.value);
  };

  const downloadContract = (event) => {
    event.preventDefault();

    console.log("downloadContract start");
    console.log("contractContract start");

    const subjectId = subject?.subject?.subjectId;
    //console.log(subject);

    if (subjectId)
      api
        .contractContract(sessionId, subjectId)
        .finally(() => console.log("contractContract Promise is ready"))
        .then((data) => {
          console.log("contractContract data", data);

          if (data.document?.data) {
            const pdfBase64 = "data:application/pdf;base64," + data.document.data;
            //console.log(pdfBase64)

            triggerBase64Download(pdfBase64, "mytips-agreement");
          }
        })
        .catch((err) => {
          console.error("contractContract", err);
        });
  };

  return (
    <div className="content">
      <div className="container">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-thumbs-up mr-1"></i> Padavėjų administravimas
            </h3>
          </div>
          <div className="card-body">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Paieška</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={inputCode}
                      value={inputCode}
                      onInput={(e) => setInputCode(e.target.value)}
                    ></input>
                    <br />
                    <button type="submit" className="btn btn-success">
                      Rodyti
                    </button>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Ieškoti pagal</label>

                    <select
                      value={selectValue}
                      onChange={onChangeValue}
                      className="custom-select"
                    >
                      <option value="PHONE">Telefonas</option>
                      <option value="CONTRACTNO">Kontrakto numeris</option>
                      <option value="PAN">My tips kortelės numeris</option>
                      <option value="EMAIL">El. pašto adresas</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>

            {subject && (
              <React.Fragment>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Vardas</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={subject.subject?.privateEntity?.firstName}
                      ></input>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Pavardė</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={subject.subject?.privateEntity?.lastName}
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Asmens kodas</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={subject.subject?.code}
                      ></input>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Mokesčių mokėtojo identifikacinis numeris</label>

                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={subject.subject?.code}
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Adresas</label>

                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={subject.addresses[0]?.formattedAddress}
                      ></input>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Telefono numeris</label>

                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={subject.contacts[0]?.contact}
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>El. pašto adresas</label>

                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={subject.contacts[1]?.contact}
                      ></input>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Banko sąskaitos numeris</label>

                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={subject.bankAccount?.account}
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>My tips kortelės numeris</label>

                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={subject.card?.pan}
                      ></input>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Pasirašyti dokumentai</label>

                      <button
                        type="button"
                        className="form-control btn btn-default"
                        onClick={downloadContract}
                      >
                        parsisiųsti
                      </button>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>

        {subject && (
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">
                <i className="fas fa-thumbs-up mr-1"></i> Veiksmų statistika
              </h3>
            </div>
            <div className="card-body">
              <React.Fragment>
                <form onSubmit={onDateSubmit}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Data nuo</label>

                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={endDate}
                          dateFormat="yyyy-MM-dd"
                          className="form-control"
                          locale="lt"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Data iki</label>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          dateFormat="yyyy-MM-dd"
                          className="form-control"
                          locale="lt"
                        />
                      </div>
                    </div>
                    <ShowButtonBlock text="Rodyti" />
                  </div>
                </form>
                <br />

                <ul className="nav nav-tabs" id="custom-content-below-tab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="actions-tab"
                      data-toggle="pill"
                      href="#actions"
                      role="tab"
                      aria-controls="actions"
                      aria-selected="true"
                    >
                      Veiksmai
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="transactions-tab"
                      data-toggle="pill"
                      href="#transactions"
                      role="tab"
                      aria-controls="transactions"
                      aria-selected="false"
                    >
                      Transakcijos
                    </a>
                  </li>
                </ul>

                <div className="tab-content" id="custom-content-below-tabContent">
                  <div
                    className="tab-pane fade active show"
                    id="actions"
                    role="tabpanel"
                    aria-labelledby="actions-tab"
                  >
                    {dataActions && Object.keys(dataActions).length > 0 && (
                      <DataTable
                        columns={columnsAction}
                        data={dataActions}
                        noDataComponent="Nėra duomenų"
                        paginationComponentOptions={paginationComponentOptions}
                        pagination
                      />
                    )}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="transactions"
                    role="tabpanel"
                    aria-labelledby="transactions-tab"
                  >
                    {dataItems && Object.keys(dataItems).length > 0 && (
                      <DataTable
                        columns={columns}
                        data={dataItems}
                        noDataComponent="Nėra duomenų"
                        paginationComponentOptions={paginationComponentOptions}
                        pagination
                      />
                    )}
                  </div>
                </div>
              </React.Fragment>
            </div>

            <div className="card-footer clearfix">
              <Link to="/" className="btn btn-info">
                Atgal
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SubjectStats;
