import { useEffect } from "react";
import { useUserContext } from "src/shared/hooks";
import { useSubject } from "src/shared/hooks/useSubjectRefresh";

function Subject() {
  // context
  const { user } = useUserContext();
  const getSubject = useSubject();

  useEffect(() => {
    if (!user?.subject) {
      getSubject();
    }
  }, [getSubject, user]);

  return;

  /*
  return (
    <div className="content">
      <div className="container">
        <div className="callout callout-info">
          <h5>User info:</h5>
          <small>{user && JSON.stringify(user)}</small>
        </div>
      </div>
    </div>
  );
  */
}

export default Subject;
