import { GetContractResponse } from "src/shared/types/contracts/responses/GetContractResponse";
import Config from "src/components/Config/Config";
import { ResponseWrapper } from "src/shared/types/ResponseWrapper";
import {
  SignContractRequest,
  SignContractResponse,
  SignContractStatusResponse,
} from "src/shared/types/contracts";
import { BaseResponse } from "src/shared/types/BaseResponse";

class apiContract {
  contractContract = (
    sessionId: string,
    subjectId: string
  ): Promise<GetContractResponse> => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiContractContract.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
        body: JSON.stringify({
          subjectId: subjectId,
          contrTypeCode: "TIPS",
        }),
      };

      fetch(Config.REST_URL + Config.REST_API.apiContractContract.PATH, requestOptions)
        .then((res) => {
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<GetContractResponse>): void => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  signContract = (
    sessionId: string,
    body: SignContractRequest
  ): Promise<SignContractResponse> => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiContractSignContract.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
        body: JSON.stringify(body),
      };

      fetch(
        Config.REST_URL + Config.REST_API.apiContractSignContract.PATH,
        requestOptions
      )
        .then((res) => {
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<SignContractResponse>): void => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  checkContract = (sessionId: string, subjectId: string): Promise<BaseResponse> => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiContractCheckContract.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
      };

      fetch(
        Config.REST_URL +
          Config.REST_API.apiContractCheckContract.PATH +
          "?contrTypeCode=TIPS&subjectId=" +
          subjectId,
        requestOptions
      )
        .then((res) => {
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<BaseResponse>) => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  checkSignContract = (
    sessionId: string,
    token: string
  ): Promise<SignContractStatusResponse> => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiContractCheckSignContract.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
      };

      fetch(
        Config.REST_URL +
          Config.REST_API.apiContractCheckSignContract.PATH +
          "?signSessionId=" +
          token,
        requestOptions
      )
        .then((res) => {
          //console.warn(res);
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<SignContractStatusResponse>) => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            if (data?.value?.signStatus == Config.REST_RESULTCODE.WAITING) {
              reject(Config.REST_RESULTCODE.WAITING);
              return;
            }

            if (data?.value?.signStatus == Config.REST_RESULTCODE.REJECTED) {
              reject(Config.REST_RESULTCODE.REJECTED);
              return;
            }

            resolve(data.value);
            return;
          }

          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export default apiContract;
