import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const NoPage = () => {
  const navigate = useNavigate();

  const handleGoToHomeClick = useCallback(
    (event) => {
      event.preventDefault();
      navigate("/");
    },
    [navigate]
  );

  return (
    <div className="content">
      <div className="container">
        <div className="d-flex flex-column align-items-center">
          <h1 style={{ fontSize: "9rem", fontWeight: 400, lineHeight: 1.4 }}>
            4<span style={{ fontSize: "7.5rem" }} className="far fa-frown"></span>4
          </h1>
          <h3>Page Not Found</h3>
          <h6>The page you-re looking for does not seem to exist</h6>
          <button className="btn btn-success my-3" onClick={handleGoToHomeClick}>
            Go to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoPage;
