import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "jquery/dist/jquery";
import "bootstrap/dist/js/bootstrap.bundle";
import "admin-lte/dist/js/adminlte.min.js";
import Footer from "./components/Footer/Footer";

// remove all logs from prod

/* eslint-disable @typescript-eslint/no-empty-function */
if (process.env.NODE_ENV !== "development") console.log = () => {};

// bootstrap
//import "bootstrap/dist/css/bootstrap.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <App />

  /*
  DEVELOPER VERSION
    <React.StrictMode>
    <App />
  </React.StrictMode>
  */
);

const footer = ReactDOM.createRoot(document.getElementById("footer"));
footer.render(
  <React.StrictMode>
    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
