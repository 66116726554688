import React, { useEffect, useState } from "react";
import UniversalCookies from "universal-cookie";
import { useSearchParams, Link } from "react-router-dom";

function Cookies() {
  const [searchParams, setSearchParams] = useSearchParams();
  if (searchParams.get("_cookies") !== null) return;

  //const [cookies, setCookies] = useState(false);
  const universalCookies = new UniversalCookies();

  let getCookies = () => {
    let cookie = universalCookies.get("tips_cookies");
    return Boolean(cookie);
  };

  let setCookie = () => {
    universalCookies.set("tips_cookies", true, { path: "/" });
  };

  const currentCookies = getCookies();

  let openModal = (element) => {
    //console.log(currentCookies);
    if (element != null && !currentCookies) element.click();
  };

  return (
    <React.Fragment>
      <button
        type="button"
        className="btn btn-default"
        data-toggle="modal"
        data-target="#modal-default"
        style={{ display: "none" }}
        onClick={(e) => e.preventDefault()}
        ref={openModal}
      >
        Launch Cookies Modal
      </button>

      <div
        data-backdrop="static"
        className="modal fade show"
        id="modal-default"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Šioje svetainėje naudojami slapukai</h4>
            </div>
            <div className="modal-body">
              <p>
                Kai lankotės mūsų svetainėje ir duodate sutikimą, mes naudojame slapukus,
                kad galėtume rinkti duomenis apibendrinti statistiką, pagerinti savo
                paslaugas bei prisiminti jūsų pasirinkimus būsimiems apsilankymams
                svetainėje.
                <br />
                Savo duotą sutikimą bet kada galėsite atšaukti pakeisdami savo interneto
                naršyklės nustatymus ir ištrindami įrašytus slapukus. Detalesnė
                informacija skelbiama skiltyje{" "}
                <a href="/CookiesPolicy/?_cookies">Slapukų politika</a>.
              </p>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={setCookie}
              >
                SUTINKU
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Cookies;
