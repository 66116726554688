import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useUserContext } from "src/shared/hooks";
import api from "../../api/helperPromise";
import { format } from "date-fns";
import { toast } from "react-toastify";

import DataTable from "react-data-table-component";

function Cards() {
  const [dataItems, setDataItems] = useState(false);

  // context
  const { user, setUser } = useUserContext();

  //return;
  const sessionId = user?.session?.sessionId;

  // table
  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
      compact: true,
      minWidth: "2%",
      maxWidth: "4%",
    },
    {
      name: "Kortelės numeris",
      selector: (row) => row.pan,
      sortable: true,
    },
    {
      name: "Statusas",
      selector: (row) => row.statusName,
      sortable: true,
      right: true,
      minWidth: "250px",
      maxWidth: "250px",
    },
    {
      name: "Galiojimo data",
      selector: (row) => row.expireDate,
      sortable: true,
      right: true,
      maxWidth: "150px",
    },

    {
      name: "",
      button: true,
      selector: (row) => (row.isActive ? <ButtonDeactivate row={row} /> : ""),
      sortable: true,
      compact: true,
      minWidth: "150px",
    },
  ];
  const paginationComponentOptions = {
    rowsPerPageText: "Puslapio eilės",
    rangeSeparatorText: "iš",
  };

  const ButtonDeactivate = ({ row }) => (
    <button
      className="btn btn-danger btn-sm"
      onClick={deactivateHandler}
      value={`${row.pan}`}
    >
      Blokuoti kortelė
    </button>
  );

  const deactivateHandler = (event) => {
    const cardId = event.target.value;

    if (cardId) {
      console.log(cardId);

      if (window.confirm("Ar tikrai norite anuliuoti kortelę?")) {
        disableCard(cardId);
      }
    }
  };

  const setCardContext = (message, messageType) => {
    const userMerge = {
      ...user,
      ...{ message: message },
      ...{ messageType: messageType },
    };

    // remove subject
    delete userMerge.subject;

    setUser(userMerge);
    //console.warn("setSignContext", userMerge)
  };

  const disableCard = (pan) => {
    console.log("disableCard start");

    const subjectId = user?.subject?.subject?.subjectId;

    const body = {
      subjectId: subjectId,
      pan: pan,
    };

    api
      .disableCard(sessionId, body)
      .finally(() => console.log("disableCard Promise is ready"))
      .then((data) => {
        console.log("disableCard data", data);

        cardsList();

        const message =
          "Jūsų kortelė sėkmingai anuliuota. Naują kortelę užregistruokite Paslaugos gavėjo anketoje.";
        setCardContext(message, "warning");
      })
      .catch((err) => {
        console.log("disableCard", err);

        if (err.value?.result?.messageText) toast.error(err.value?.result?.messageText);
      });
  };

  const cardsList = () => {
    console.log("cardsList start");

    const subjectId = user?.subject?.subject?.subjectId;

    api
      .cardCards(sessionId, subjectId)
      .finally(() => console.log("cardsList Promise is ready"))
      .then((data) => {
        console.log("cardsList data", data);

        var newData = [];

        data.cards.map((item, i) => {
          //console.log(item, i)

          var expireDate = new Date(item.expireDate);

          const tempData = {
            id: i + 1,
            pan: item.pan,
            status: item.status,
            statusName: item.statusName,
            isActive: item.isActive,
            expireDate: format(expireDate, "yyyy-MM-dd"),
          };

          console.log(tempData);
          newData.push(tempData);
        });

        setDataItems(newData);
      })
      .catch((err) => {
        console.log("cardsList", err);
      });
  };

  useEffect(() => {
    cardsList();
  }, []); // eslint-disable-line

  // chech if user
  if (!user || !user.subject) return;

  return (
    <div className="content">
      <div className="container">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-credit-card mr-1"></i> <strong>my tips</strong>{" "}
              kortelės
            </h3>
          </div>
          <div className="card-body">
            {dataItems && (
              <DataTable
                columns={columns}
                data={dataItems}
                noDataComponent="Nėra duomenų"
                paginationComponentOptions={paginationComponentOptions}
                pagination
              />
            )}
          </div>

          <div className="card-footer clearfix">
            <Link to="/" className="btn btn-info">
              Atgal
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
