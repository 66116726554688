import React, { useState } from "react";
import { useSignOut, useUserContext } from "src/shared/hooks";
import Countdown from "react-countdown";
import Config from "../Config/Config";
import api from "../../api/helperPromise";
import TopBarProgress from "react-topbar-progress-indicator";
import { toast } from "react-toastify";
import { LoginButton } from "./LoginButton";
//import { Toastify } from "./toastify";

const LoginFormSidba = ({ childFormInfo, getSession }) => {
  TopBarProgress.config({
    barColors: {
      0: "#2ed876",
      0.8: "#24AC5E",
      "1.0": "#2ed876",
    },
    shadowBlur: 10,
  });
  const [topBarProgress, setTopBarProgress] = useState(false);

  // context
  const { mutateUser } = useUserContext();
  const { signOut } = useSignOut();
  //return;

  //states
  // 30303039903
  const [inputSmartId, setInputSmartId] = useState("");
  const [inputSmartIdInvalid, setInputSmartIdInvalid] = useState("");
  const [labelCode, setLabelCode] = useState("");
  const [loading, setLoading] = useState(false);

  const setAuthContext = (auth) => {
    const userMerge = { auth: auth, login: true };
    mutateUser(userMerge);
  };

  const signInStatus = (sessionId, accessToken) => {
    console.log("authAuthenticationStatus start");
    setTopBarProgress(true);

    api
      .authAuthenticationStatus(sessionId, accessToken)
      .finally(() => console.log("authAuthenticationStatus Promise is ready"))
      .then((data) => {
        console.log("authAuthenticationStatus data", data);
        toast.success("Sveiki " + data.authPersonData.firstName);

        if (data.authPersonData?.code) {
          setAuthContext(data.authPersonData);
        }

        setTopBarProgress(false);
        setLoading(false);
        childFormInfo("");
      })
      .catch((err) => {
        console.warn("authAuthenticationStatus", err);

        if (err == Config.REST_RESULTCODE.WAITING) {
          console.log("authAuthenticationStatus setTimeout");
          setTimeout(function () {
            signInStatus(sessionId, accessToken);
          }, 2500);

          // FIX
          //} else if (Config.REST_RESULTCODE.REJECTED) {
        } else {
          if (err.value?.result?.messageText) toast.error(err.value?.result?.messageText);

          setLabelCode("");
          setTopBarProgress(false);
          setLoading(false);
          childFormInfo("");
        }
      });
  };

  const signIn = async () => {
    console.log("authAuthenticate start");
    setTopBarProgress(true);
    setLoading(true);

    // disable child tab
    childFormInfo("SIDBA");

    const authType = "DOKOBIT_SIDBA";
    const authData = {
      code: inputSmartId,
      country: "LT",
    };
    const body = {
      authType: authType,
      authData: authData,
    };

    try {
      const session = await getSession();
      const data = await api.authAuthenticate(session.sessionId, body);
      console.log("authAuthenticate Promise is ready");

      console.log("authAuthenticate data", data);

      if (data.controlCode && data.accessToken) {
        toast.success("Kontrolinis kodas išsiųstas į jūsų įrenginį:" + data.controlCode);

        setLabelCode(data.controlCode);

        signInStatus(session.sessionId, data.accessToken);
      } else {
        setTopBarProgress(false);
        setLoading(false);
        childFormInfo("");

        toast.error("controlCode: " + data.controlCode);
        if (!data.controlCode) {
          signOut();
        }
      }
    } catch (err) {
      console.log("authAuthenticate", err);

      if (err.value?.result?.messageText) toast.error(err.value?.result?.messageText);

      if (err.result?.messageText) toast.error(err.result?.messageText);

      if (!err.value?.result?.messageText && !err.result?.messageText) {
        toast.error("Autentifikavimo klaida");
      }

      setTopBarProgress(false);
      setLoading(false);
      childFormInfo("");
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    let valudateFlag = true;

    // code test
    if (inputSmartId.length >= 11) {
      setInputSmartIdInvalid("");
    } else {
      valudateFlag = false;
      setInputSmartIdInvalid("is-invalid");
    }

    if (valudateFlag) signIn();
  };

  const handleChange = (event) => {
    const result = event.target.value.replace(/[^0-9]/gi, "");

    setInputSmartId(result);
  };

  // countdown
  const Completionist = () => <span>(o_O)</span>;

  const rendererCountdown = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span className="btn btn-light btn-xl">
          {minutes}:{seconds}m
        </span>
      );
    }
  };

  return (
    <React.Fragment>
      {topBarProgress && <TopBarProgress />}

      <div className="card-body px-1 p-sm-3">
        <p className="login-box-msg">Užsiregistruoti naudojant Smart-ID</p>

        <form onSubmit={onSubmit}>
          {labelCode ? (
            <React.Fragment>
              <div className="text-center">
                <p>Kontrolinis kodas išsiųstas į jūsų įrenginį</p>
                <h1 className="alert alert-light text-xl">{labelCode}</h1>
                <p>
                  Jūsų kontrolinio kodo galiojimas baigsis už{" "}
                  <Countdown date={Date.now() + 119000} renderer={rendererCountdown} />
                </p>
                <p>
                  <small>
                    Jei aukščiau pavaizduotas kodas sutampa su kodu, kurį matote telefono
                    ekrane, įveskite savo sPIN1 kodą.
                  </small>
                </p>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className={`form-control ${inputSmartIdInvalid}`}
                  placeholder="Asmens kodas"
                  value={inputSmartId}
                  onChange={handleChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>

                <span className="error invalid-feedback">
                  Neteisingai nurodytas asmens kodas
                </span>
              </div>

              <div className="row d-flex justify-content-end">
                <LoginButton loading={loading} />
              </div>
            </React.Fragment>
          )}
        </form>
      </div>
    </React.Fragment>
  );
};

export default LoginFormSidba;
