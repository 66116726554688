let docUrl1 = process.env.PUBLIC_URL + "/share/BENDROSIOS_SALYGOS.pdf";
let docUrl2 = process.env.PUBLIC_URL + "/share/KAINORASTIS.pdf";

export const checkboxes = [
  {
    label:
      "Paslaugos gavėjas patvirtina, kad Sutarties Paslaugos gavėjo anketoje nurodė tikslius ir teisingus duomenis.",
    value: 1,
  },
  {
    label: `Pasirašydamas Sutartį Paslaugos gavėjas patvirtina, kad yra susipažinęs ir sutinka su <a href="${docUrl1}" target="_blank">Mokėjimų pervedimų paslaugų bendrosiomis sąlygomis</a> ir <a href="${docUrl2}" target="_blank">Kainoraščiu</a>. Paslaugos gavėjas supranta ir sutinka, kad Mokėjimo įstaiga pagal Sutartyje aprašytas sąlygas informavus Paslaugos gavėją turi teisę vienašališkai keisti Sutarties Bendrosios dalies sąlygas ir mokėjimų surinkimo mokesčių apskaičiavimo tvarką ir jų dydį. Paslaugos gavėjui nesutikus su pakeitimais, Paslaugos gavėjas turi teise vienašališkai nutraukti Sutartį.`,
    value: 1,
  },
  {
    label:
      "Paslaugos gavėjas sutinka, kad Mokėjimo įstaiga perduotų jo asmens ir Arbatpinigių duomenis Valstybinei mokesčių inspekcijai ir teisės aktuose numatytais atvejais teisėsaugos ir priežiūros, taip pat kitoms valstybės ir savivaldybės institucijoms, vykdant teisės aktų reikalavimus.",
    value: 1,
  },
  {
    label:
      "Paslaugos gavėjas sutinka, kad informacija apie jo asmens duomenis ir gautus Arbatpinigius (jų dydį) būtų perduodama Prekybininkui.",
    value: 1,
  },
];
