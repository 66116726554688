import React from "react";

/* eslint-disable no-irregular-whitespace*/
function CookiesPolicy() {
  return (
    <div className="content">
      <div className="container">
        <h2>SLAPUKAI (ANGL. COOKIES)</h2>

        <p>
          <strong>Bendrosios su Slapukais susijusios nuostatos</strong>
        </p>
        <p>
          Tinklapyje mes naudojame Slapukus (mažas bylas, įra&scaron;omas į Jūsų
          kompiuterio ar kito įrenginio kietąjį diską, angl. &bdquo;
          <em>cookies</em>&ldquo;) tam, kad užtikrintume tinkamą Tinklapio veikimą,
          sklandų naudojimąsi Tinklapiu, taip pat Tinklapio lankomumo statistikos ir
          vartotojo patirties gerinimo tikslais.
        </p>
        <p>
          Jums apsilankius Tinklapyje, pateikiamas i&scaron;&scaron;okantis
          prane&scaron;imas su nuoroda į &scaron;ią Privatumo politiką, kuris informuoja
          apie Slapukų naudojimą Tinklapyje. Toliau naudodamiesi Tinklapiu Jūs
          patvirtinate, jog leidžiate Bendrovei įra&scaron;yti į Jūsų galinį įrenginį
          (kompiuterį, plan&scaron;etinį kompiuterį, i&scaron;manųjį telefoną aparatą ir
          pan.) Slapukus, kurie yra nurodyti Privatumo politikoje. Jeigu nepageidaujate ar
          nesutinkate, kad į Jūsų kompiuterį ar kitą galinį įrenginį būtų įra&scaron;omi
          Slapukai, Jūs visada galite atsisakyti Slapukų naudodamiesi &scaron;iame
          Privatumo politikos skyriuje nurodytomis instrukcijomis.
        </p>
        <p>Žemiau pateikiama informacija, kurią Jūs turėtumėte žinoti apie Slapukus:</p>
        <p>
          Slapukai gali būti &bdquo;seanso slapukai&ldquo; (angl. &bdquo;
          <em>session cookies</em>&ldquo;), kurie yra naudojami tik kol nar&scaron;ysite
          internete ir yra automati&scaron;kai i&scaron;trinami uždarius nar&scaron;yklę,
          &bdquo;nuolatiniai slapukai&ldquo; (angl. &bdquo;<em>persistent cookies</em>
          &ldquo;), t. y. tokie, kurie saugomi Jūsų galiniame įrenginyje (kompiuteryje,
          plan&scaron;etiniame kompiuteryje, i&scaron;maniajame telefone ir pan.) tol, kol
          baigiasi nustatytas Slapuko naudojimo laikas arba Jūs atsisakote Slapuko,
          pasinaudodami vienu i&scaron; būdų, apra&scaron;ytų &scaron;ioje Privatumo
          politikoje, arba &bdquo;trečiosios &scaron;alies slapukai&ldquo;, t. y. tokie,
          kurie naudojami asmens, kuris nėra Tinklapio valdytojas.
        </p>
        <p>
          Jūs visada galite atsisakyti mūsų Slapukų, jeigu Jūsų nar&scaron;yklė tą
          padaryti leidžia, vis dėlto toks atsisakymas gali turėti įtakos Jūsų patirčiai
          naudojantis Tinklapiu arba blokuoti prieigą prie Tinklapio. Slapukus galite
          i&scaron;diegti dviem būdais:
        </p>
        <ul>
          <li>
            savo nar&scaron;yklėje galite peržiūrėti savo kompiuteryje jau įdiegtus
            Slapukus ir i&scaron;trinti juos po vieną arba visus i&scaron; karto.
            Veiksmai, kuriuos reikia atlikti, skiriasi (instrukcijas dėl Slapukų
            populiariausių nar&scaron;yklių atžvilgiu rasite paspaudę ant toliau pateikto
            nar&scaron;yklės pavadinimo 
            <a href="http://help.opera.com/Windows/9.51/en/cookies.html">
              <span>
                <span>Opera</span>
              </span>
            </a>
            , 
            <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">
              <span>
                <span>Firefox</span>
              </span>
            </a>
            , 
            <a href="https://support.google.com/chrome/answer/95647?hl=lt&amp;co=GENIE.Platform%3DDesktop">
              <span>
                <span>Chrome</span>
              </span>
            </a>
            , 
            <a href="https://support.apple.com/kb/PH19214?locale=en_US">
              <span>
                <span>Safari</span>
              </span>
            </a>
            , 
            <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">
              <span>
                <span>Internet Explorer</span>
              </span>
            </a>
            ), todėl konkretūs atliktini veiksmai turėtų būti nustatomi atsižvelgiant į
            Jūsų naudojamą nar&scaron;yklę;
          </li>
          <li>
            be to, galite nustatyti savo interneto nar&scaron;yklę taip, kad &scaron;i
            blokuotų Slapukus arba gautumėte perspėjimą apie bet kokio Slapuko įdiegimą.
            Veiksmai, kuriuos reikia atlikti, skiriasi kiekvienoje nar&scaron;yklėje,
            todėl konkretūs atliktini veiksmai turėtų būti nustatomi atsižvelgiant į Jūsų
            naudojamą nar&scaron;yklę;
          </li>
        </ul>
        <p>
          Atkreipiame dėmesį, kad Slapukus taip pat gali naudoti ir Bendrovės partneriai
          ar kiti tretieji asmenys, kurių Tinklapio valdytojas nekontroliuoja. Už tokių
          asmenų veiksmus Bendrovė negali būti ir nėra atsakinga. Įtardami, kad be Jūsų
          sutikimo Bendrovės partneriai ar kiti tretieji asmenys, kurių Tinklapio
          valdytojas nekontroliuoja, naudoja Slapukus, turėtumėte kreiptis į konkretų
          partnerį ar kitą trečiąjį asmenį.
        </p>
        <p>
          <strong>Tinklapyje naudojamų Slapukų sąra&scaron;as</strong>
        </p>
        <p>Mes Tinklapyje naudojame toliau lentelėje nurodytus Slapukus.</p>
        <p>
          Naudodami žemiau nurodytus statistikos Slapukus, kurie renka anoniminę
          informacija ir teikia jos ataskaitas, ir &bdquo;
          <em>Google Analytics</em>&ldquo; įrankį, Bendrovė gali sužinoti, kaip lankytojai
          sąveikauja su Tinklapiu. Atkreipiame dėmesį, jog &bdquo;
          <em>Google Analytics</em>&ldquo; įrankį suteikia &bdquo;Google&ldquo;,
          vadovaujantis 
          <a href="https://marketingplatform.google.com/about/analytics/terms/lt/">
            <span>
              <span>paslaugų teikimo sąlygomis</span>
            </span>
          </a>
          . &bdquo;<em>Google</em>&ldquo; turi prieigą prie &scaron;iuo įrankiu surinktų
          statistinių duomenų ir gali juos tvarkyti serveriuose JAV, tačiau yra
          įsipareigojusi taikyti ES &ndash; JAV Privatumo skydo principus, kurie
          užtikrina, kad paslaugų teikėjas atitinka ES privatumo standartų reikalavimus.
          Daugiau informacijos apie &bdquo;
          <em>Google Analytics</em>&ldquo; rasite spustelėję 
          <a href="https://support.google.com/analytics/answer/6004245">
            <span>
              <span>čia</span>
            </span>
          </a>
          . Alternatyvus būdas i&scaron;jungti &bdquo;<em>Google Analytics</em>
          &ldquo; &ndash; įdiegti nedidelį įskiepį, siūlomą &bdquo;
          <em>Google</em>&ldquo;, kurį galite rasti 
          <a href="https://tools.google.com/dlpage/gaoptout?hl=en">
            <span>
              <span>čia</span>
            </span>
          </a>
          .
        </p>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>Pavadinimas</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Sukūrimo momentas</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Paskirtis</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Galiojimo pabaiga</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Naudojami duomenys</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_utma</p>
              </td>
              <td>
                <p>Įėjus į Tinklapį</p>
              </td>
              <td>
                <p>
                  Google slapukas, skirtas rinkti statistinei informacijai (apsilankymų
                  kiekiui nustatyti) (Google Analytics įrankis)
                </p>
              </td>
              <td>
                <p>2 metai</p>
              </td>
              <td>
                <p>Unikalus ID numeris</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_utmb</p>
              </td>
              <td>
                <p>Įėjus į Tinklapį</p>
              </td>
              <td>
                <p>
                  Google slapukas, skirtas rinkti statistinei informacijai (apsilankymų
                  trukmei nustatyti) (Google Analytics įrankis)
                </p>
              </td>
              <td>
                <p>6 mėnesiai</p>
              </td>
              <td>
                <p>Unikalus ID numeris</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_utmc</p>
              </td>
              <td>
                <p>Įėjus į Tinklapį</p>
              </td>
              <td>
                <p>
                  Google slapukas, skirtas rinkti statistinei informacijai (apsilankymų
                  trukmei nustatyti) (Google Analytics įrankis)
                </p>
              </td>
              <td>
                <p>Iki nar&scaron;yklės lango uždarymo</p>
              </td>
              <td>
                <p>Unikalus ID numeris</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_utmt</p>
              </td>
              <td>
                <p>Įėjus į Tinklapį</p>
              </td>
              <td>
                <p>
                  Google slapukas, skirtas pagreitinti užklausų siuntimą serveriui (Google
                  Analytics įrankis)
                </p>
              </td>
              <td>
                <p>10 minučių</p>
              </td>
              <td>
                <p>Sveikasis skaičius</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_utmz</p>
              </td>
              <td>
                <p>Įėjus į Tinklapį</p>
              </td>
              <td>
                <p>
                  Google slapukas, skirtas rinkti statistinei informacijai (nustatyti
                  apsilankymo &scaron;altinį) (Google Analytics įrankis)
                </p>
              </td>
              <td>
                <p>6 mėnesiai</p>
              </td>
              <td>
                <p>Tekstinis kintamasis su informacija apie apsilankymo &scaron;altinį</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_ga</p>
              </td>
              <td>
                <p>Įėjus į Tinklapį</p>
              </td>
              <td>
                <p>Google slapukas, skirtas atskirti vartotojams.</p>
              </td>
              <td>
                <p>2 metai</p>
              </td>
              <td>
                <p>Tekstinis kintamasis</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_gid</p>
              </td>
              <td>
                <p>Įėjus į Tinklapį</p>
              </td>
              <td>
                <p>Google slapukas, skirtas atskirti vartotojams.</p>
              </td>
              <td>
                <p>24 valandos</p>
              </td>
              <td>
                <p>Tekstinis kintamasis</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>tips_cookies</p>
              </td>
              <td>
                <p>Įėjus į Tinklapį</p>
              </td>
              <td>
                <p>
                  Slapukas, skirtas įsiminti vartotojo sutikimą dėl slapukų naudojimo.
                </p>
              </td>
              <td>
                <p>1 metai</p>
              </td>
              <td>
                <p>Sveikasis skaičius</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CookiesPolicy;
