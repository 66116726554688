import React, { ReactElement, useCallback, ChangeEvent, MouseEvent } from "react";
import TablePagination from "@material-ui/core/TablePagination";
import { TablePaginationActions } from "./TablePaginationActions/TablePaginationActions";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import "./styles.css";

type CustomTablePaginationProps = {
  rowsPerPage: number;
  rowCount: number;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage?: (page: number) => void;
  currentPage: number;
  children: ReactElement | null;
  paginationRowsPerPageOptions: number[];
};

type LabelDisplayedRowsProps = {
  from: number;
  to: number;
  count: number;
  page: number;
};

export type PaginationActionsProps = {
  rowsPerPage: number;
  count: number;
  onPageChange: (event: MouseEvent<HTMLButtonElement> | null, page: number) => void;
  page: number;
};

const labelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsProps) =>
  `${from}-${to} iš ${count}`;

export const CustomTablePagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
  currentPage,
  paginationRowsPerPageOptions,
  children,
}: CustomTablePaginationProps) => {
  const onPageChange = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      event.preventDefault();
      onChangePage(page);
    },
    [onChangePage]
  );

  const onRowsPerPageChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      onChangeRowsPerPage(Number(target.value)),
    [onChangeRowsPerPage]
  );

  const PaginationActions = useCallback(
    (props: PaginationActionsProps): ReactElement => {
      const onChange = ({ target }: ChangeEvent<{ name?: string; value: unknown }>) => {
        onChangeRowsPerPage(Number(target.value));
      };

      return (
        <TablePaginationActions {...props}>
          <Select
            className="mobile-only"
            value={rowsPerPage}
            onChange={onChange}
            disableUnderline={true}
          >
            {paginationRowsPerPageOptions.map((pageSize) => (
              <MenuItem key={pageSize} value={pageSize}>
                {pageSize}
              </MenuItem>
            ))}
          </Select>
        </TablePaginationActions>
      );
    },
    [paginationRowsPerPageOptions, onChangeRowsPerPage, rowsPerPage]
  );

  return (
    <>
      {children}
      <TablePagination
        classes={{
          caption: "pagination-caption",
          selectRoot: "pagination-select",
          toolbar: "pagination-toolbar",
        }}
        component="nav"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        page={currentPage - 1}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        ActionsComponent={PaginationActions}
        labelDisplayedRows={labelDisplayedRows}
        labelRowsPerPage="Puslapio eilės"
        rowsPerPageOptions={paginationRowsPerPageOptions}
      />
    </>
  );
};
