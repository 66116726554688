import React, { ReactElement } from "react";
import "./styles.css";

type SpinnerProps = {
  enabled: boolean;
};

export const Spinner = ({ enabled }: SpinnerProps): ReactElement => {
  return (
    enabled && (
      <div className="spinner-border spinner-border-sm custom-spinner" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )
  );
};
