import React, { useState, useCallback } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useUserContext } from "src/shared/hooks";
import api from "../../api/helperPromise";
import { format } from "date-fns";
import { sub } from "date-fns";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import lt from "date-fns/locale/lt";
import Tooltip from "@material-ui/core/Tooltip";

import DataTable from "react-data-table-component";
import { ShowButtonBlock } from "../base/ShowButtonBlock";
import {
  CustomTableFooter,
  CustomTablePagination,
  FooterBlock,
} from "../base/CustomTablePagination";
import { formatCurrency } from "src/shared/utils";

registerLocale("lt", lt);

const CustomCell = ({ row }) => {
  return (
    <div>
      <strong>{row.merchantCode}</strong>
      <br />
      {row.merchantName}
    </div>
  );
};

const columns = [
  {
    name: "#",
    selector: (row) => row.id,
    sortable: true,
    compact: true,
    minWidth: "2%",
    maxWidth: "4%",
  },
  {
    name: "Transakcijos ID",
    selector: (row) => row.acquirerTransactionId,
    sortable: true,
    minWidth: "150px",
  },
  {
    name: "Suma",
    selector: (row) => row.amount,
    format: (row) => formatCurrency(row.amount, row.currencyCode),
    sortable: true,
    right: true,
    minWidth: "100px",
  },
  {
    name: "Prekybininkas/Įmonės Kodas",
    selector: (row) => row.merchantCode,
    sortable: true,
    minWidth: "200px",
    center: true,
    cell: (row) => <CustomCell row={row} />,
  },
  {
    name: "MID",
    selector: (row) => row.mid,
    sortable: true,
    minWidth: "100px",
  },
  {
    name: "TID",
    selector: (row) => row.tid,
    sortable: true,
    minWidth: "100px",
  },
  {
    name: "RRN",
    selector: (row) => row.rrn,
    sortable: true,
    minWidth: "150px",
  },
  {
    name: "Data",
    selector: (row) => row.date,
    sortable: true,
    minWidth: "100px",
  },
  {
    name: "Apdorojimo data",
    selector: (row) => row.processingDate,
    sortable: true,
    minWidth: "150px",
  },
];

function AcquirerTransactions() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataItems, setDataItems] = useState(false);

  // context
  const { user } = useUserContext();

  // GET
  const filename = searchParams.get("_filename");
  const id = searchParams.get("_id");
  const date = searchParams.get("_date");

  // set dates
  // const [startDate, setStartDate] = useState(new Date(date));
  const [endDate, setEndDate] = useState(new Date(date));
  const [startDate, setStartDate] = useState(sub(endDate, { days: 30 }));

  const CustomPagination = useCallback(
    (props) => {
      const amount =
        dataItems.length > 0
          ? dataItems.reduce((amount, row) => amount + row.amount, 0)
          : 0;
      const currencyCode = dataItems.length > 0 ? dataItems[0].currencyCode : null;

      return (
        <CustomTablePagination {...props}>
          <CustomTableFooter>
            <FooterBlock compact={true} width="50px">
              Viso:
            </FooterBlock>
            <FooterBlock empty={true} couldGrow={true} />
            <FooterBlock align="right" width="200px">
              <span className="mobile-only mr-1">Suma: </span>
              {formatCurrency(amount, currencyCode)}
            </FooterBlock>
            <FooterBlock align="center" width="200px">
              Kiekis: {dataItems.length}
            </FooterBlock>
            <FooterBlock empty={true} width="600px" />
          </CustomTableFooter>
        </CustomTablePagination>
      );
    },
    [dataItems]
  );

  // chech if user
  if (!user || !user.subject) return;

  //return;
  const sessionId = user?.session?.sessionId;

  const financeAcquirerTransactions = () => {
    console.log("financeAcquirerTransactions start");

    var startDateFormated = format(startDate, "yyyy-MM-dd");
    var endDateFormated = format(endDate, "yyyy-MM-dd");

    const body = {
      fileId: id,
      dateFrom: startDateFormated,
      dateTo: endDateFormated,
    };

    api
      .financeAcquirerTransactions(sessionId, body)
      .finally(() => console.log("financeAcquirerTransactions Promise is ready"))
      .then((data) => {
        console.log("financeAcquirerTransactions data", data);

        var newData = [];

        data.acquirerTransactions.map((item, i) => {
          //console.log(item, i)

          var date = new Date(item.date);
          let processingDateFormated = "Neapdorota";
          if (item.processingDate) {
            var processingDate = new Date(item.processingDate);
            processingDateFormated = format(processingDate, "yyyy-MM-dd");
          }

          const tempData = {
            id: i + 1,
            acquirerTransactionId: item.acquirerTransactionId,
            amount: item.amount,
            currencyCode: item.currencyCode,
            merchantCode: item.merchantCode,
            merchantName: item.merchantName,
            mid: item.mid,
            rrn: item.rrn,
            status: item.status,
            tid: item.tid,
            date: format(date, "yyyy-MM-dd"),
            processingDate: processingDateFormated,
          };

          //console.log(tempData);
          newData.push(tempData);
        });

        setDataItems(newData);
      })
      .catch((err) => {
        console.log("financeAcquirerTransactions", err);
      });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    financeAcquirerTransactions();
  };

  return (
    <div className="content">
      <div className="container">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title w-100 text-truncate">
              <i className="fas fa-thumbs-up mr-1"></i> Acquirer Transactions List.
              <br />
              <Tooltip title={filename} placement="top">
                <span>
                  Filename: <strong>{filename}</strong>
                </span>
              </Tooltip>
            </h3>
          </div>
          <div className="card-body">
            <form onSubmit={onSubmit}>
              {filename && id && (
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Data nuo</label>

                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={endDate}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        locale="lt"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Data iki</label>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        locale="lt"
                      />
                    </div>
                  </div>
                  <ShowButtonBlock text="Rodyti" />
                </div>
              )}
            </form>

            {dataItems && (
              <DataTable
                columns={columns}
                data={dataItems}
                noDataComponent="Nėra duomenų"
                paginationComponent={CustomPagination}
                pagination
              />
            )}
          </div>

          <div className="card-footer clearfix">
            <Link to="/" className="btn btn-info">
              Atgal
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcquirerTransactions;
