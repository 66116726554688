import React, { useState, useCallback } from "react";

import LoginFormSidba from "./LoginFormSidba";
import LoginFormMidba from "./LoginFormMidba";
import LoginFormUnba from "./LoginFormUnba";

import "./styles.css";

// logo
import logo from "src/mytips-logo.svg";
import { useSession, useUserContext } from "src/shared/hooks";

function LoginForm() {
  const [dataChild, setDataChild] = useState("active");
  const { user } = useUserContext();
  const { sessionCheck, sessionOpen } = useSession();

  const childFormInfo = useCallback(
    (data) => {
      setDataChild(data);
      //console.log(data)
    },
    [setDataChild]
  );

  const getSession = useCallback(() => {
    if (!user?.session?.sessionId) {
      return sessionOpen();
    }

    return sessionCheck(user?.session?.sessionId);
  }, [sessionCheck, sessionOpen, user?.session?.sessionId]);

  return (
    <div className="content">
      <div className="container">
        <section className="col-lg-8 offset-lg-2">
          <h2>
            <img src={logo} alt="Ashburn Tips" className="mytips-logo d-inline" />{" "}
            <span className="d-none d-sm-inline">arbatpinigių paslauga</span>
          </h2>
        </section>

        <section className="col-lg-8 offset-lg-2">
          <div className="card card-success card-outline">
            <div className="card-header">
              <h3 className="card-title">
                <i className="fas fa-edit"></i> Patvirtinkite savo tapatybę
              </h3>
            </div>

            <div className="card-header p-0 py-2 border-bottom-0  d-block d-sm-none">
              <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      dataChild == "MIDBA" ? "disabled" : dataChild
                    }`}
                    id="custom-tabs-smartid-tab"
                    data-toggle="pill"
                    href="#vert-tabs-smartid"
                    role="tab"
                    aria-controls="vert-tabs-smartid"
                    aria-selected="true"
                  >
                    Smart-ID
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${dataChild == "SIDBA" ? "disabled" : ""}`}
                    id="custom-tabs-msign-tab"
                    data-toggle="pill"
                    href="#vert-tabs-msign"
                    role="tab"
                    aria-controls="vert-tabs-msign"
                    aria-selected="false"
                  >
                    Mobilusis parašas
                  </a>
                </li>
                {/*  
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-msign-tab"
                    data-toggle="pill"
                    href="#vert-tabs-home"
                    role="tab"
                    aria-controls="vert-tabs-home"
                    aria-selected="false"
                  >
                    El. paštas ir slaptažodis
                  </a>
                  </li>
                */}
              </ul>
            </div>

            <div className="card-body px-1 p-sm-3">
              <div className="row">
                <div className="col-5 col-sm-4 d-none d-sm-block">
                  <div
                    className="nav flex-column nav-tabs h-100"
                    id="vert-tabs-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <a
                      className={`nav-link ${
                        dataChild == "MIDBA" ? "disabled" : dataChild
                      }`}
                      id="vert-tabs-smartid-tab"
                      data-toggle="pill"
                      href="#vert-tabs-smartid"
                      role="tab"
                      aria-controls="vert-tabs-smartid"
                      aria-selected="false"
                    >
                      Smart-ID
                    </a>

                    <a
                      className={`nav-link ${dataChild == "SIDBA" ? "disabled" : ""}`}
                      id="vert-tabs-msign-tab"
                      data-toggle="pill"
                      href="#vert-tabs-msign"
                      role="tab"
                      aria-controls="vert-tabs-msign"
                      aria-selected="false"
                    >
                      Mobilusis parašas
                    </a>

                    {/*  
                    <a
                      className="nav-link"
                      id="vert-tabs-home-tab"
                      data-toggle="pill"
                      href="#vert-tabs-home"
                      role="tab"
                      aria-controls="vert-tabs-home"
                      aria-selected="true"
                    >
                      El. paštas ir slaptažodis
                    </a>
                    */}
                  </div>
                </div>
                <div className="col-12 col-sm-8">
                  <div className="tab-content" id="vert-tabs-tabContent">
                    <div
                      className="tab-pane fade  show active"
                      id="vert-tabs-smartid"
                      role="tabpanel"
                      aria-labelledby="vert-tabs-smartid-tab"
                    >
                      <LoginFormSidba
                        childFormInfo={childFormInfo}
                        getSession={getSession}
                      />
                    </div>

                    <div
                      className="tab-pane fade "
                      id="vert-tabs-msign"
                      role="tabpanel"
                      aria-labelledby="vert-tabs-msign-tab"
                    >
                      <LoginFormMidba
                        childFormInfo={childFormInfo}
                        getSession={getSession}
                      />
                    </div>
                    {/*  
                    <div
                      className="tab-pane text-left fade"
                      id="vert-tabs-home"
                      role="tabpanel"
                      aria-labelledby="vert-tabs-home-tab"
                    >
                      <LoginFormUnba />
                    </div>
                    */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default LoginForm;
