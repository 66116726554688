import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { toast, ToastItem, ToastOptions } from "react-toastify";
import { ToastDialogActions } from "src/components/toastify/components";
import { getUser, useUserContext } from "./useUserContext";
import { defaultPath } from "src/shared/constants/appRoutes";
import { UserData } from "../types/UserData";

const sessionRenewAvailablePeriod = 60000;

let sessionRenewTimeout: NodeJS.Timeout = null;

function calculateProgress(sessionExpireAfter: number): number {
  if (sessionRenewAvailablePeriod > sessionExpireAfter) {
    const progress = sessionExpireAfter / sessionRenewAvailablePeriod;
    return progress <= 0 ? 0 : progress;
  }

  return 1;
}

function CalculateSessionExpiration(expireAt: string) {
  return new Date(expireAt).getTime() - new Date().getTime();
}

export const useScheduleSessionRenew = () => {
  const { mutateUser } = useUserContext();
  const toastId = React.useRef(null);
  const navigate = useNavigate();

  const eraseSession = useCallback(() => {
    const userMerge: UserData = {
      session: null,
      login: false,
    };

    mutateUser(userMerge);
  }, [mutateUser]);

  const options: ToastOptions = useMemo(
    () => ({
      autoClose: false,
      closeButton: ToastDialogActions,
      type: toast.TYPE.INFO,
      hideProgressBar: false,
      position: toast.POSITION.TOP_CENTER,
      pauseOnHover: false,
      draggable: true,
      icon: false,
      pauseOnFocusLoss: false,
    }),
    []
  );

  const startProgressUpdating = useCallback(
    (expireAt: string): void => {
      let toastProgressRefershTimer: NodeJS.Timer = null;

      const unsubscribe = toast.onChange((payload: ToastItem) => {
        switch (payload.status) {
          case "removed":
            if (payload.id === toastId.current) {
              if (toastProgressRefershTimer) {
                clearInterval(toastProgressRefershTimer);
              }

              unsubscribe();
            }
            break;
        }
      });

      toastProgressRefershTimer = setInterval(() => {
        if (!toastId.current) {
          return;
        }

        const sessionExpireAfter = CalculateSessionExpiration(expireAt);
        const progress = calculateProgress(sessionExpireAfter);
        if (progress === 0) {
          unsubscribe();
          clearInterval(toastProgressRefershTimer);
          toast.dismiss(toastId.current);
          eraseSession();
          navigate(defaultPath);
          return;
        }

        options.progress = progress;
        toast.update(toastId.current, options);
      }, 100);
    },
    [eraseSession, navigate, options]
  );

  const scheduleSessionRenew = useCallback(
    (expireAt: string): void => {
      const sessionExpireAfter = CalculateSessionExpiration(expireAt);
      if (sessionExpireAfter < 0) return;

      let sessionRenewDelay = 0;
      if (sessionExpireAfter > sessionRenewAvailablePeriod) {
        sessionRenewDelay = sessionExpireAfter - sessionRenewAvailablePeriod;
      }

      console.log(`Session renew scheduled after ${sessionRenewDelay} ms`);
      sessionRenewTimeout = setTimeout(() => {
        sessionRenewTimeout = null;

        const user = getUser();
        if (!user?.login) {
          console.log("User is not logged in");
          return;
        }

        const sessionExpireAfter = CalculateSessionExpiration(expireAt);
        if (sessionExpireAfter <= 0) {
          return;
        }

        options.progress = calculateProgress(sessionExpireAfter);
        toastId.current = toast("Ar norėtumėte atnaujinti sesiją?", options);
        startProgressUpdating(expireAt);
      }, sessionRenewDelay);
    },
    [startProgressUpdating, options]
  );

  const scheduleSessionRenewIfNecessary = useCallback(
    (expireAt: string) => {
      if (sessionRenewTimeout) {
        console.log("Session renew already scheduled");
        return;
      }

      scheduleSessionRenew(expireAt);
    },
    [scheduleSessionRenew]
  );

  const unscheduleSessionRenewIfNecessary = useCallback(() => {
    if (sessionRenewTimeout) {
      clearTimeout(sessionRenewTimeout);
      sessionRenewTimeout = null;
    }
  }, []);

  const replaceScheduleSessionRenew = useCallback(
    (expireAt: string) => {
      unscheduleSessionRenewIfNecessary();
      scheduleSessionRenewIfNecessary(expireAt);
    },
    [scheduleSessionRenewIfNecessary, unscheduleSessionRenewIfNecessary]
  );

  return {
    scheduleSessionRenewIfNecessary,
    unscheduleSessionRenewIfNecessary,
    replaceScheduleSessionRenew,
  };
};
