import { SubjectResponse } from "../types/subjects";

export const retrieveContacts = (subject: SubjectResponse) => {
  let labelPhone = "";
  let labelEmail = "";

  if (subject?.contacts && Array.isArray(subject?.contacts)) {
    subject.contacts.forEach((obj) => {
      if (obj.type == "P") labelPhone = obj.contact;
      if (obj.type == "E") labelEmail = obj.contact;
    });
  }

  return {
    labelPhone,
    labelEmail,
  };
};
