import { useSignOut, useUserContext } from "src/shared/hooks";
import api from "src/api/helperPromise";
import { SubjectResponse } from "../types/subjects";
import { useCallback } from "react";

export const useSubject = () => {
  const { user, mutateUser } = useUserContext();
  const { signOut } = useSignOut();

  const setSubjectContext = useCallback(
    (subject: SubjectResponse) => {
      const userMerge = { subject: subject };
      mutateUser(userMerge);
    },
    [mutateUser]
  );

  const getSubject = useCallback((): void => {
    console.log("subjectGetSubject start");
    if (!user || !user.auth?.code || !user.session) {
      console.log("Auth code ", user?.auth?.code);
      console.log("Session ", user?.session);
      return;
    }

    const sessionId = user.session.sessionId;
    const subjectCode = user.auth.code;
    api
      .subjectGetSubject(sessionId, subjectCode, null)
      .finally(() => console.log("subjectGetSubject Promise is ready"))
      .then((data) => {
        console.log("subjectGetSubject data", data);
        if (data.subject) setSubjectContext(data);
      })
      .catch((err) => {
        console.warn("subjectGetSubject", err);
        signOut();
      });
  }, [setSubjectContext, signOut, user]);

  return getSubject;
};
