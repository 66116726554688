import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { CustomTooltip } from "src/components/base/CustomTooltip";

type TileLinkProps = {
  link: string;
  text: string;
  tooltipTitle?: string;
  showTooltip?: boolean;
};

export const TileLink = ({
  link,
  text,
  tooltipTitle,
  showTooltip,
}: TileLinkProps): ReactElement => {
  return (
    <Link to={link}>
      <CustomTooltip placement="top" title={tooltipTitle} disabled={!showTooltip}>
        <small>
          <span className={showTooltip ? "text-danger" : ""}>
            {text} <i className="fas fa-arrow-circle-right"></i>
          </span>
        </small>
      </CustomTooltip>
    </Link>
  );
};
