import React from "react";
import "./styles.css";

type ShowButtonBlockProps = {
  text: string;
};

export const ShowButtonBlock = ({ text }: ShowButtonBlockProps) => {
  return (
    <div className="d-flex col-md-1">
      <button type="submit" className="btn btn-success show-btn">
        {text}
      </button>
    </div>
  );
};
