import { MouseEvent, useCallback } from "react";
import { useUserContext } from "./useUserContext";
import { useNavigate } from "react-router-dom";
import api from "src/api/helperPromise";
import { UserData } from "../types/UserData";
import { useScheduleSessionRenew } from "../hooks";

type UseSignOutReturn = {
  signOut: () => Promise<string>;
  onSignOut: (e: MouseEvent<unknown>) => void;
};

export const useSignOut = (): UseSignOutReturn => {
  const { user, mutateUser } = useUserContext();
  const navigate = useNavigate();
  const { unscheduleSessionRenewIfNecessary } = useScheduleSessionRenew();
  const sessionId = user?.session?.sessionId;

  const setAuthContext = useCallback(() => {
    const userMerge: UserData = {
      session: null,
      login: false,
      sign: undefined,
      auth: undefined,
      subject: undefined,
      message: undefined,
    };

    mutateUser(userMerge);
  }, [mutateUser]);

  const signOut = useCallback((): Promise<string> => {
    console.log("signOut start");

    if (!sessionId) {
      unscheduleSessionRenewIfNecessary();
      setAuthContext();
      return new Promise((resolve) => resolve(""));
    }

    const logoutPromise = api.authLogout(sessionId);
    logoutPromise
      .finally(() => {
        console.log("signOut Promise is ready");
        unscheduleSessionRenewIfNecessary();
        setAuthContext();

        navigate("/");
      })
      .then((data) => {
        console.log("signOut data", data);
      })
      .catch((err) => {
        console.error("signOut", err);
      });

    return logoutPromise;
  }, [navigate, setAuthContext, unscheduleSessionRenewIfNecessary, sessionId]);

  const onSignOut = useCallback(
    (e: MouseEvent<unknown>) => {
      e.preventDefault();

      signOut();
    },
    [signOut]
  );

  return {
    signOut,
    onSignOut,
  };
};
