import React from "react";
import { useSignOut, useUserContext } from "src/shared/hooks";
import Config from "../Config/Config";

import { Link } from "react-router-dom";

// logo
import logo from "src/logo.svg";

function NavTop() {
  const { user } = useUserContext();
  const { onSignOut } = useSignOut();

  return (
    <div className="wrapper">
      <nav className="main-header navbar navbar-expand-md navbar-light navbar-white">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="Ashburn Tips" className="brand-image" />
            <span className="brand-text font-weight-bold d-none d-sm-inline"></span>
          </Link>

          <button
            className="navbar-toggler order-1 collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="navbar-collapse order-3 collapse" id="navbarCollapse">
            <ul className="navbar-nav ml-auto">
              {user?.login && (
                <React.Fragment>
                  {(user?.auth?.role == Config.ROLE.TIPSOPERATOR ||
                    user?.auth?.role == Config.ROLE.TIPSACCOUNTANT) && (
                    <li className="nav-item">
                      <Link to="/SubjectStats" className="nav-link">
                        Padavėjų administravimas
                      </Link>
                    </li>
                  )}

                  {user?.auth?.role == Config.ROLE.TIPSACCOUNTANT && (
                    <React.Fragment>
                      <li className="nav-item dropdown">
                        <a
                          id="dropdownSubMenu1"
                          href="#"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="nav-link dropdown-toggle"
                        >
                          Finansų administravimas
                        </a>
                        <ul
                          aria-labelledby="dropdownSubMenu1"
                          className="dropdown-menu border-0 shadow"
                        >
                          <li className="nav-item">
                            <Link to="/Uploader" className="dropdown-item">
                              Banko ataskaita
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link to="/PaymentDocumentList" className="dropdown-item">
                              Banko pavedimai
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link to="/BankTransactions" className="dropdown-item">
                              Gauti pavedimai
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}

              <li className="nav-item">
                <Link to="/About" className="nav-link">
                  Apie
                </Link>
              </li>

              <li className="nav-item">
                <a
                  href={`${process.env.PUBLIC_URL}/share/BENDROSIOS_SALYGOS.pdf`}
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Bendrosios sąlygos
                </a>
              </li>

              <li className="nav-item">
                <a
                  href={`${process.env.PUBLIC_URL}/share/KAINORASTIS.pdf`}
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Kainoraštis
                </a>
              </li>

              <li className="nav-item">
                <Link to="/Faq" className="nav-link">
                  D.U.K.
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/Contact" className="nav-link">
                  Kontaktai
                </Link>
              </li>
            </ul>
          </div>

          <div className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
            {user?.login ? (
              <a href="#" onClick={onSignOut} className="btn btn-warning">
                <span className="d-none d-sm-block">
                  <i className="fas fa-times"> </i> Atsijungti
                </span>
                <span className="d-block d-sm-none">
                  <i className="fas fa-times"> </i>
                </span>
              </a>
            ) : (
              <Link to="/" className="btn btn-warning">
                <span className="d-none d-sm-block">
                  <i className="fas fa-credit-card"> </i> my tips
                </span>
                <span className="d-block d-sm-none">
                  <i className="fas fa-credit-card"> </i>
                </span>
              </Link>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavTop;
