import { BaseResponse } from "src/shared/types/BaseResponse";
import {
  AcquirerTransactionsRequest,
  BankTransactionsRequest,
  TransactionRequest,
  TransactionsResponse,
  TransactionsSummaryResponse,
  TransactionSummaryRequest,
  AcquirerTransactionsResponse,
  BankTransactionsResponse,
  AcquirerTotalTransactionsRequest,
  AcquirerTotalTransactionsResponse,
  PaymentDocumentListRequest,
  PaymentDocumentListResponse,
  AddTransactionRequest,
  UpdateTransactionRequest,
} from "src/shared/types/finances";
import { ResponseWrapper } from "src/shared/types/ResponseWrapper";
import Config from "../components/Config/Config";

class apiFinance {
  financeTransaction = (
    sessionId: string,
    body: TransactionRequest
  ): Promise<TransactionsResponse> => {
    return new Promise<TransactionsResponse>((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiFinanceTransaction.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
        body: JSON.stringify(body),
      };

      fetch(Config.REST_URL + Config.REST_API.apiFinanceTransaction.PATH, requestOptions)
        .then((res) => {
          //console.warn(res);
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<TransactionsResponse>): void => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  financeTransactionSummary = (
    sessionId: string,
    body: TransactionSummaryRequest
  ): Promise<TransactionsSummaryResponse> => {
    return new Promise<TransactionsSummaryResponse>((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiFinanceTransactionSummary.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
        body: JSON.stringify(body),
      };

      fetch(
        Config.REST_URL + Config.REST_API.apiFinanceTransactionSummary.PATH,
        requestOptions
      )
        .then((res) => {
          //console.warn(res);
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<TransactionsSummaryResponse>): void => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  financeBankTransactions = (
    sessionId: string,
    body: BankTransactionsRequest
  ): Promise<BankTransactionsResponse> => {
    return new Promise<BankTransactionsResponse>((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiFinanceBankTransactions.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
        body: JSON.stringify(body),
      };

      fetch(
        Config.REST_URL + Config.REST_API.apiFinanceBankTransactions.PATH,
        requestOptions
      )
        .then((res) => {
          //console.warn(res);
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<BankTransactionsResponse>): void => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  financeAcquirerTransactions = (
    sessionId: string,
    body: AcquirerTransactionsRequest
  ): Promise<AcquirerTransactionsResponse> => {
    return new Promise<AcquirerTransactionsResponse>((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiFinanceAcquirerTransactions.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
        body: JSON.stringify(body),
      };

      fetch(
        Config.REST_URL + Config.REST_API.apiFinanceAcquirerTransactions.PATH,
        requestOptions
      )
        .then((res) => {
          //console.warn(res);
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data) => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  financeAcquirerTotalTransactions = (
    sessionId: string,
    body: AcquirerTotalTransactionsRequest
  ): Promise<AcquirerTotalTransactionsResponse> => {
    return new Promise<AcquirerTotalTransactionsResponse>((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiFinanceAcquirerTotalTransactions.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
        body: JSON.stringify(body),
      };

      fetch(
        Config.REST_URL + Config.REST_API.apiFinanceAcquirerTotalTransactions.PATH,
        requestOptions
      )
        .then((res) => {
          //console.warn(res);
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<AcquirerTotalTransactionsResponse>): void => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  financePaymentDocumentList = (
    sessionId: string,
    body: PaymentDocumentListRequest
  ): Promise<PaymentDocumentListResponse> => {
    return new Promise<PaymentDocumentListResponse>((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiFinancePaymentDocumentList.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
        body: JSON.stringify(body),
      };

      fetch(
        Config.REST_URL + Config.REST_API.apiFinancePaymentDocumentList.PATH,
        requestOptions
      )
        .then((res) => {
          //console.warn(res);
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<PaymentDocumentListResponse>): void => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  financeAddTransaction = (
    sessionId: string,
    body: AddTransactionRequest
  ): Promise<BaseResponse> => {
    return new Promise<BaseResponse>((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiFinanceAddTransaction.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
        body: JSON.stringify(body),
      };

      fetch(
        Config.REST_URL + Config.REST_API.apiFinanceAddTransaction.PATH,
        requestOptions
      )
        .then((res) => {
          //console.warn(res);
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<BaseResponse>) => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  financeUpdateTransaction = (
    sessionId: string,
    body: UpdateTransactionRequest
  ): Promise<BaseResponse> => {
    return new Promise<BaseResponse>((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiFinanceUpdateTransaction.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
        body: JSON.stringify(body),
      };

      fetch(
        Config.REST_URL + Config.REST_API.apiFinanceUpdateTransaction.PATH,
        requestOptions
      )
        .then((res) => {
          //console.warn(res);
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<BaseResponse>): void => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export default apiFinance;
