import React, { useState, useEffect } from "react";
import { useUserContext } from "src/shared/hooks";
import api from "../../api/helperPromise";
import { format } from "date-fns";
import { sub } from "date-fns";
import lt from "date-fns/locale/lt";
import { formatCurrency } from "src/shared/utils";

function DashboardStats() {
  const [dataItems, setDataItems] = useState(false);

  // context
  const { user } = useUserContext();

  //return;
  const sessionId = user?.session?.sessionId;

  const financeTransaction = () => {
    console.log("financeTransaction start - dashboardStats.jsx");

    let endDate = new Date();
    let startDate = sub(endDate, { days: 30 });

    var startDateFormated = format(startDate, "yyyy-MM-dd");
    var endDateFormated = format(endDate, "yyyy-MM-dd");

    const subjectId = user.subject.subject.subjectId;
    let creditor = { subjectId: subjectId };

    const body = {
      transactionDate: startDateFormated,
      transactionDateTo: endDateFormated,
      creditor: creditor,
    };

    api
      .financeTransaction(sessionId, body)
      .finally(() => console.log("financeTransaction Promise is ready"))
      .then((data) => {
        console.log("financeTransaction data", data);

        var newData = [];

        data.transactions
          .sort(
            (t1, t2) =>
              new Date(t2.transactionValueDate) - new Date(t1.transactionValueDate)
          )
          .map((item, i) => {
            //console.log(item, i)

            // TODO
            if (i > 2) return;

            var date = new Date(item.transactionValueDate);

            const tempData = {
              id: i,
              debitorAccount: item.debitorAccount.subjectName,
              amount: formatCurrency(item.amount.amount, item.amount.currency),
              subjectAddress: item.debitorAccount.address,
              transactionValueDate: date,
              name: item.name,
            };

            //console.log(tempData);
            newData.push(tempData);
          });

        setDataItems(newData);
      })
      .catch((err) => {
        console.log("financeTransaction", err);
      });
  };

  useEffect(() => {
    financeTransaction();
  }, []); // eslint-disable-line

  // chech if user
  if (!user || !user.subject) return;

  return (
    <div className="card-body table-responsive p-0">
      <table className="table table-striped table-valign-middle">
        <thead>
          <tr>
            <td>Data</td>
            <td>Vieta</td>
            <td>
              Paliktų
              <br />
              arbatpinigių
              <br />
              suma
            </td>
          </tr>
        </thead>
        <tbody>
          {dataItems &&
            dataItems.map(
              ({ id, debitorAccount, subjectAddress, amount, transactionValueDate }) => {
                return (
                  <tr key={id}>
                    <td>
                      <h4 className="mb-0">
                        {format(transactionValueDate, "dd", { locale: lt })}
                      </h4>
                      {format(transactionValueDate, "MMM", { locale: lt })}
                    </td>
                    <td>
                      {debitorAccount} <br />
                      <small>{subjectAddress}</small>
                    </td>
                    <td>
                      {amount} <br />
                    </td>
                  </tr>
                );
              }
            )}
        </tbody>
      </table>
    </div>
  );
}

export default DashboardStats;
