import { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSignOut, useUserContext } from "src/shared/hooks";
import api from "src/api/helperPromise";
import { useScheduleSessionRenew } from "src/shared/hooks/useScheduleSessionRenew";
import { SessionData } from "../types/sessions/SessionData";
import { AuthData } from "../types/AuthData";
import { defaultPath } from "src/shared/constants/appRoutes";
import { UserData } from "../types/UserData";

let openingSessionPromise: Promise<SessionData>;
let authenticationStatus = false;

export const useSession = () => {
  // context
  const { mutateUser } = useUserContext();
  //console.clear();
  const location = useLocation();
  const { scheduleSessionRenewIfNecessary, replaceScheduleSessionRenew } =
    useScheduleSessionRenew();
  const { signOut } = useSignOut();

  // const
  const navigate = useNavigate();

  const navigateToDefaultUrlIfNecessary = useCallback(
    (currentLocation: string) => {
      if (currentLocation !== defaultPath) {
        navigate(defaultPath);
      }
    },
    [navigate]
  );

  const setSessionContext = useCallback(
    (session: SessionData, login: boolean, auth: AuthData) => {
      const userMerge: UserData = {
        session: session,
        login: login,
        auth: auth,
      };
      mutateUser(userMerge);
    },
    [mutateUser]
  );

  const clearSession = useCallback(() => {
    setSessionContext(null, false, null);
  }, [setSessionContext]);

  const authInfo = useCallback(
    (sessionId: string, dataSession: SessionData) => {
      console.log("authInfo start");
      //authInfoResult(sessionId)
      api
        .authAuthInfo(sessionId)
        .finally(() => console.log("authInfo Promise is ready"))
        .then((data) => {
          console.log("authInfo data", data);
          if (data?.userProfile) {
            const authPersonData = data.userProfile;
            setSessionContext(dataSession, authenticationStatus, authPersonData);
          } else {
            console.warn("userProfile IS NULL");
            setSessionContext(dataSession, authenticationStatus, null);
          }
        })
        .catch((err: unknown) => {
          console.error("authInfo", err);
          signOut();
        });
    },
    [setSessionContext, signOut]
  );

  const sessionOpen = useCallback((): Promise<SessionData> => {
    if (openingSessionPromise) {
      return openingSessionPromise;
    }

    console.log("sessionOpen start");
    //sessionOpenResult()
    openingSessionPromise = new Promise<SessionData>((resolve, reject) => {
      api
        .sessionOpen()
        .finally(() => console.log("sessionOpen Promise is ready"))
        .then((data) => {
          console.log("sessionOpen data", data);
          if (data?.session?.sessionId) {
            setSessionContext(data.session, false, null);
            replaceScheduleSessionRenew(data.session.expireAt);
            resolve(data.session);
            return;
          }

          reject(data);
        })
        .catch((err: unknown) => {
          console.error("sessionOpen", err);
          reject(err);
        })
        .finally(() => (openingSessionPromise = null));
    });

    return openingSessionPromise;
  }, [setSessionContext, replaceScheduleSessionRenew]);

  const reopenKilledSession = useCallback(
    (oldSession: SessionData): Promise<SessionData> => {
      navigateToDefaultUrlIfNecessary(location.pathname);
      setSessionContext(oldSession, false, null);
      return sessionOpen();
    },
    [navigateToDefaultUrlIfNecessary, setSessionContext, sessionOpen, location.pathname]
  );

  const sessionCheck = useCallback(
    (sessionId: string): Promise<SessionData> => {
      console.log("sessionCheck start");

      return new Promise<SessionData>((resolve, reject) => {
        //sessionGetResult(sessionId)
        api
          .sessionGet(sessionId)
          .finally(() => console.log("sessionCheck Promise is ready"))
          .then((data) => {
            console.log("sessionCheck data", data);
            if (data?.session?.sessionId) {
              if (
                data.session.authenticationStatus === "ANONYMOUS" ||
                (data.session.authenticationStatus === "CONFIRMED" &&
                  data.session.status == "ACTIVE")
              ) {
                handleLiveSession();
              } else if (data.session.authenticationStatus == "CLOSED") {
                console.log("sessionCheck CLOSED");
                reopenKilledSession(data.session)
                  .then((sesson) => resolve(sesson))
                  .catch((err) => reject(err));
              } else {
                handleLiveSession();
              }
            } else {
              reopenKilledSession(null)
                .then((sesson) => resolve(sesson))
                .catch((err) => reject(err));
            }

            function handleLiveSession() {
              console.log("sessionCheck OK");
              const userMerge: UserData = {
                session: data.session,
              };
              mutateUser(userMerge);
              resolve(data.session);
            }
          })
          .catch((err) => {
            console.warn("sessionCheck", err);

            reopenKilledSession(null)
              .then((sesson) => resolve(sesson))
              .catch((err) => reject(err));
          });
      });
    },
    [reopenKilledSession, mutateUser]
  );

  const sessionGet = useCallback(
    (sessionId: string) => {
      console.log("sessionGet start");

      //sessionGetResult(sessionId)
      api
        .sessionGet(sessionId)
        .finally(() => console.log("sessionGet Promise is ready"))
        .then((data) => {
          console.log("sessionGet data", data);

          if (data?.session?.sessionId) {
            if (data.session.authenticationStatus == "CONFIRMED") {
              authenticationStatus = true;
              authInfo(sessionId, data.session);
              scheduleSessionRenewIfNecessary(data.session.expireAt);
            } else {
              setSessionContext(data.session, false, null);
            }
          } else {
            setSessionContext(null, false, null);
          }
        })
        .catch((err) => {
          console.warn("sessionGet", err);
          setSessionContext(null, false, null);
        });
    },
    [authInfo, scheduleSessionRenewIfNecessary, setSessionContext]
  );

  return {
    sessionGet,
    sessionOpen,
    sessionCheck,
    clearSession,
  };
};
