import React, { useContext, useState, useEffect } from "react";
import { useUserContext } from "src/shared/hooks";
import api from "../../api/helperPromise";
import { format } from "date-fns";
import { sub } from "date-fns";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

function Chart() {
  // context
  const { user } = useUserContext();

  const [dataItems, setDataItems] = useState(false);
  const [periodWeek, setPeriodWeek] = useState("btn-success");
  const [periodMonth, setPeriodMonth] = useState("btn-default");

  //return;
  const sessionId = user?.session?.sessionId;

  const generateChartData = (labels, data) => {
    const chartData = {
      labels: labels,
      datasets: [
        {
          label: "Pervestų arbatpinigių suma EUR",
          data: data,
          borderWidth: 1,
          borderColor: "rgb(46,216,118)",
          backgroundColor: "rgba(46,216,118, 1)",
        },
      ],
    };

    return chartData;
  };

  const acquirerTotalTransactions = (period) => {
    console.log("acquirerTotalTransactions start - chart.jsx");

    let startDate = new Date();
    let endDate = new Date();

    //let mode = "WEEK";
    let mode = "DAY";

    if (period == "week") {
      startDate = sub(startDate, { days: 7 });
    } else {
      startDate = sub(startDate, { months: 12 });

      mode = "MONTH";
    }

    var startDateFormated = format(startDate, "yyyy-MM-dd");
    var endDateFormated = format(endDate, "yyyy-MM-dd");

    const subjectId = user.subject.subject.subjectId;

    const body = {
      subjectId: subjectId,
      dateFrom: startDateFormated,
      dateTo: endDateFormated,
      mode: mode,
    };

    api
      .financeAcquirerTotalTransactions(sessionId, body)
      .finally(() => console.log("acquirerTotalTransactions Promise is ready"))
      .then((data) => {
        console.log("acquirerTotalTransactions data", data);

        var newDataAmount = [];
        var newDataDate = [];

        data.acquirerTotalTransactions.map((item, i) => {
          //var date = new Date(item.date);

          newDataAmount.push(item.amount);
          //newDataDate.push(format(date, "yyyy-MM-dd"));
          newDataDate.push(item.date);
        });

        let newChartData = generateChartData(newDataDate, newDataAmount);

        setDataItems(newChartData);
      })
      .catch((err) => {
        console.log("acquirerTotalTransactions", err);
      });
  };

  const changePeriod = () => {
    console.log(periodWeek);
    console.log(periodMonth);
    if (periodWeek == "btn-success") {
      setPeriodWeek("btn-default");
      setPeriodMonth("btn-success");

      acquirerTotalTransactions();
    } else {
      setPeriodMonth("btn-default");
      setPeriodWeek("btn-success");

      acquirerTotalTransactions("week");
    }
  };

  useEffect(() => {
    acquirerTotalTransactions("week");
  }, []); // eslint-disable-line

  // chech if user
  if (!user || !user.subject) return;

  return (
    <div className="card">
      <div className="card-header border-0">
        <div className="d-flex justify-content-between">
          <h3 className="card-title">Arbatpinigių ataskaita</h3>

          <div className="card-tools">
            <div className="btn-group">
              <button
                type="button"
                className={`btn ${periodWeek}`}
                onClick={changePeriod}
              >
                Diena
              </button>
              <button
                type="button"
                className={`btn ${periodMonth}`}
                onClick={changePeriod}
              >
                Mėnuo
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="position-relative mb-4">
          {dataItems && <Bar options={options} data={dataItems} />}
        </div>
      </div>
    </div>
  );
}

export default Chart;
