import { width } from "@material-ui/system";
import React, { ReactElement } from "react";
import "./styles.css";

type FooterBlockProps = {
  width: string;
  align?: "left" | "center" | "right";
  couldGrow?: boolean;
  empty?: boolean;
  compact?: boolean;
  children?: ReactElement | string | null;
};

export const FooterBlock = ({
  width,
  align,
  couldGrow,
  empty,
  compact,
  children,
}: FooterBlockProps): ReactElement => {
  const actualWidth = width || (couldGrow ? "5px" : "100px");
  return (
    <div
      className={`${empty ? "empty-" : ""}footer-block content-align-${align} ${
        couldGrow ? "growable" : ""
      } ${compact ? "compact" : ""}`}
      style={{ width: actualWidth }}
    >
      {children}
    </div>
  );
};
