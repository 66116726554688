import React from "react";

/* eslint-disable no-irregular-whitespace*/
function Contact() {
  return (
    <div className="content">
      <div className="container">
        <h2>Kontaktai</h2>

        <div className="card">
          <div className="card-body row">
            <div className="col-12 text-center d-flex align-items-center justify-content-center">
              <div className="">
                <p className="lead mb-5">
                  <br />
                  <br />
                  Telefonas: <a href="tel:+37052030959">(8-5) 20 30 959</a>
                  <br />
                  <br />
                  El.paštas:{" "}
                  <a href="mailto:mytips@ashburn.eu?subject=website">
                    mytips@ashburn.eu
                  </a>{" "}
                  <br />
                  <br />
                  UAB „ASHBURN International“, Kareivių g. 2, LT-08248 Vilnius
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
