import React, { useEffect } from "react";
import { useUserContext } from "src/shared/hooks";

import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Breadcrumb() {
  // context
  const { user, mutateUser } = useUserContext();

  useEffect(() => {
    if (user && user.message) {
      const messageText = user.message;
      const messageType = user.messageType;

      const setMessageContext = () => {
        const userMerge = {
          message: null,
          messageType: null,
        };
        mutateUser(userMerge);
      };

      const Msg = ({ closeToast, toastProps }) => (
        <div>
          <p dangerouslySetInnerHTML={{ __html: messageText }}></p>
          <button onClick={closeToast} className="btn btn-default">
            Uždaryti
          </button>
        </div>
      );

      //setTimeout(setMessageContext, 5000);

      const options = {
        toastId: "message",
        theme: "colored",
        autoClose: false,
        closeButton: false,
        //onClose: () => setMessageContext(),
        closeOnClick: false,
      };

      if (messageType == "success") toast.success(<Msg />, options);
      if (messageType == "warning") toast.warning(<Msg />, options);

      setMessageContext();
    }
  }, [mutateUser, user?.message, user?.messageType]); // eslint-disable-line

  return (
    <React.Fragment>
      <ToastContainer />
      <br />
    </React.Fragment>
  );

  // <div className={`card card-${messageType} card-outline`}>
  // dangerouslySetInnerHTML={{ __html: messageText }}
}

export default Breadcrumb;
