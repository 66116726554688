import React, { ReactElement } from "react";
import { Spinner } from "src/components/base/Spinner";

type LoginButtonProps = {
  loading: boolean;
};

export const LoginButton = ({ loading }: LoginButtonProps): ReactElement => {
  const colSize = loading ? 8 : 6;
  return (
    <div className={`col-sm-${colSize}`}>
      <button className="btn btn-success btn-block" disabled={loading}>
        <Spinner enabled={loading} />
        {loading ? "Laukiama atsakymo iš tarnybos" : "Registruotis / Prisijungti"}
      </button>
    </div>
  );
};
