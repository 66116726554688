import React, { useContext, createContext, useCallback } from "react";
import { UserData } from "../types/UserData";

type UserContextModel = {
  user: UserData;
  setUser: (user: UserData) => void;
  mutateUser: (user: UserData) => void;
};

export const UserContext = createContext<UserContextModel>(null);

let userData: UserData;

export const useUserContext = (): UserContextModel => {
  const { user, setUser: setUserToContext } = useContext(UserContext);

  const setUser = useCallback(
    (user: UserData): void => {
      userData = user;
      setUserToContext(user);
    },
    [setUserToContext]
  );

  const mutateUser = useCallback(
    (user: UserData): void => {
      const userMerge = {
        ...userData,
        ...user,
      };
      setUser(userMerge);
    },
    [setUser]
  );

  return {
    user,
    setUser,
    mutateUser,
  };
};

export const getUser = (): UserData | null => userData;
