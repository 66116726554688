import React, { ReactElement, useCallback, MouseEvent } from "react";
import "./styles.css";
import { useSignOut, useUserContext } from "src/shared/hooks";
import { useScheduleSessionRenew } from "src/shared/hooks/useScheduleSessionRenew";
import api from "src/api/helperPromise";
import { CloseButtonProps } from "react-toastify";
import { UserData } from "src/shared/types/UserData";

export const ToastDialogActions = ({
  closeToast,
}: CloseButtonProps): ReactElement<CloseButtonProps, string> => {
  const { onSignOut } = useSignOut();
  const { user, mutateUser } = useUserContext();
  const { scheduleSessionRenewIfNecessary } = useScheduleSessionRenew();

  const handleRenew = useCallback(
    (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
      e.preventDefault();
      closeToast(e);
      if (user && user.session && user.login) {
        (async () => {
          try {
            const data = await api.sessionRefresh(user.session.sessionId);
            if (data.session) {
              const userMerge: UserData = {
                session: data.session,
              };
              mutateUser(userMerge);
              scheduleSessionRenewIfNecessary(data.session.expireAt);
            }
          } catch {
            onSignOut(e);
          }
        })();
      }
    },
    [closeToast, onSignOut, mutateUser, scheduleSessionRenewIfNecessary, user]
  );

  const handleCancel = useCallback(
    (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
      e.preventDefault();
      closeToast(e);
      onSignOut(e);
    },
    [onSignOut, closeToast]
  );

  return (
    <>
      <button className="btn btn-success toast-btn btn-sm" onClick={handleRenew}>
        Atnaujinti
      </button>
      <button className="btn btn-default toast-btn btn-sm" onClick={handleCancel}>
        Atšaukti
      </button>
    </>
  );
};
