import React, { ReactElement } from "react";
import "./styles.css";

type CustomTableFooterProps = {
  children: ReactElement | null;
};

export const CustomTableFooter = ({ children }: CustomTableFooterProps): ReactElement => {
  return <div className="table-footer">{children}</div>;
};
