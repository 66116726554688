import React from "react";
import { Tooltip, TooltipProps } from "@material-ui/core";
import { ReactElement } from "react";

interface CustomTooltipProps extends TooltipProps {
  disabled: boolean;
}

export const CustomTooltip = ({
  disabled,
  children,
  ...rest
}: CustomTooltipProps): ReactElement => {
  return disabled ? children : <Tooltip {...rest}>{children}</Tooltip>;
};
