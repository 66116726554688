import { BaseResponse } from "src/shared/types/BaseResponse";
import { CardActionRequest, CardsResponse } from "src/shared/types/cards";
import { ResponseWrapper } from "src/shared/types/ResponseWrapper";
import Config from "../components/Config/Config";

class apiCard {
  cardCards = (sessionId: string, subjectId: string): Promise<CardsResponse> => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiCardCards.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
      };

      fetch(
        Config.REST_URL + Config.REST_API.apiCardCards.PATH + "?subjectId=" + subjectId,
        requestOptions
      )
        .then((res) => {
          //console.warn(res);
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<CardsResponse>) => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  disableCard = (sessionId: string, body: CardActionRequest): Promise<BaseResponse> => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiCardDisableCard.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
        body: JSON.stringify(body),
      };

      fetch(Config.REST_URL + Config.REST_API.apiCardDisableCard.PATH, requestOptions)
        .then((res) => {
          //console.warn(res);
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<BaseResponse>) => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export default apiCard;
