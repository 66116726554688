import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useUserContext } from "src/shared/hooks";
import Countdown from "react-countdown";
import Config from "../Config/Config";
import api from "src/api/helperPromise";
import PdfViewer from "../Pdfviewer/Pdfviewer";
import { triggerBase64Download } from "common-base64-downloader-react";
import { checkboxes } from "../../shared/utils/checkboxes";
import { useNavigate } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { toast } from "react-toastify";
import { useSubject } from "src/shared/hooks/useSubjectRefresh";

import "src/styles/bootstrap-steps.css";
import { retrieveContacts } from "src/shared/utils";

function Profile() {
  TopBarProgress.config({
    barColors: {
      0: "#2ed876",
      0.8: "#24AC5E",
      "1.0": "#2ed876",
    },
    shadowBlur: 10,
  });
  const [topBarProgress, setTopBarProgress] = useState(false);

  // context
  const { user, mutateUser } = useUserContext();

  const navigate = useNavigate();

  //return;
  const sessionId = user?.session?.sessionId;
  //let subjectId = "";

  let labelFirstName = user.subject?.subject?.privateEntity?.firstName;
  let labelLastName = user.subject?.subject?.privateEntity?.lastName;
  let labelCode = user.subject?.subject?.code;
  let labelFormattedAddress = user.subject?.addresses[0]?.formattedAddress;

  let labelBankAccount = user.subject?.bankAccount?.account;
  let labelCard = user.subject?.card?.pan;

  const { labelPhone, labelEmail } = retrieveContacts(user?.subject);

  //console.clear();
  //console.log(user);

  // chech if user
  if (!user || !user.subject) {
    labelFirstName = user.auth?.firstName;
    labelLastName = user.auth?.lastName;
    labelCode = user.auth?.code;
  }

  //states
  const [inputAddress, setInputAddress] = useState(
    labelFormattedAddress ? labelFormattedAddress : ""
  );
  const [inputAddressInvalid, setInputAddressInvalid] = useState("");

  const [inputPhone, setInputPhone] = useState(labelPhone);
  const [inputPhoneInvalid, setInputPhoneInvalid] = useState("");

  const [inputEmail, setInputEmail] = useState(labelEmail);
  const [inputEmailInvalid, setInputEmailInvalid] = useState("");

  const [inputBankAccount, setInputBankAccount] = useState(
    labelBankAccount ? labelBankAccount : ""
  );
  const [inputBankAccountInvalid, setInputBankAccountInvalid] = useState("");

  const [inputCard, setInputCard] = useState(labelCard ? labelCard : "9440383600010");
  const [inputCardInvalid, setInputCardInvalid] = useState("");

  // document
  const [document, setDocument] = useState();
  const [subjectId, setSubjectId] = useState();
  const [labelSingCode, setLabelSingCode] = useState("");
  const refreshSubject = useSubject();

  // checkbox
  const [checkedState, setCheckedState] = useState(
    new Array(checkboxes.length).fill(false)
  );
  const [total, setTotal] = useState(false);

  useEffect(() => {
    refreshSubject();
  }, []); //eslint-disable-line

  useEffect(() => {
    const subject = user?.subject;
    if (!subject) {
      return;
    }

    setInputAddress(subject.addresses[0]?.formattedAddress ?? "");

    const { labelPhone, labelEmail } = retrieveContacts(subject);
    setInputPhone(labelPhone);
    setInputEmail(labelEmail);

    setInputBankAccount(subject.bankAccount?.account);
    setInputCard(subject.card?.pan);
  }, [user?.subject]); //eslint-disable-line

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);

    let totalChecked = true;
    updatedCheckedState.map((item, index) => {
      if (item === false) totalChecked = false;
    });

    setTotal(totalChecked);
  };

  const setSignContext = (message, messageType) => {
    const userMerge = {
      sign: true,
      message: message,
      messageType: messageType,
      subject: undefined,
    };

    mutateUser(userMerge);
    //console.warn("setSignContext", userMerge)
  };

  const contract = (subjectId) => {
    console.log("contractContract start");
    api
      .contractContract(sessionId, subjectId)
      .finally(() => console.log("contractContract Promise is ready"))
      .then((data) => {
        console.log("contractContract data", data);
        setTopBarProgress(false);
        toast.warn(
          "Sutartį būtina pasirašyti. Spauskite mygtuką SUDARYTI SUTARTĮ apačioje.",
          { autoClose: 150000 }
        );

        if (data.document?.data) {
          setDocument(data.document.data);
        }
      })
      .catch((err) => {
        console.error("contractContract", err);

        setTopBarProgress(false);
      });
  };

  const checkContract = (subjectId) => {
    console.log("checkContract start");

    api
      .checkContract(sessionId, subjectId)
      .finally(() => console.log("checkContract Promise is ready"))
      .then((data) => {
        console.log("checkContract data", data);
        //toast.success("checkContract");

        // generate contract
        contract(subjectId);
      })
      .catch((err) => {
        console.warn("checkContract", err);

        if (err.value?.result?.messageText) toast.error(err.value?.result?.messageText);

        if (err.result?.messageText) toast.error(err.result?.messageText);

        setTopBarProgress(false);
      });
  };

  const newSubject = () => {
    console.log("subjectNewSubject start");
    setTopBarProgress(true);

    const body = {
      code: labelCode,
      privateEntity: {
        code: labelCode,
        firstName: labelFirstName,
        lastName: labelLastName,
      },
      contacts: [
        {
          contact: inputPhone,
          type: "P",
        },
        {
          contact: inputEmail,
          type: "E",
        },
      ],
      addresses: [
        {
          formattedAddress: inputAddress,
          type: "M",
        },
      ],
      bankAccount: {
        account: inputBankAccount,
        type: "IBAN",
      },
      card: {
        pan: inputCard,
      },
    };

    api
      .subjectNewSubject(sessionId, body)
      .finally(() => console.log("subjectNewSubject Promise is ready"))
      .then((data) => {
        console.log("subjectNewSubject data", data);
        toast.success("Naujas vartotojas");

        const subjectId = data.subjectId;
        setSubjectId(subjectId);
        //console.warn("subjectId", subjectId);

        if (subjectId) checkContract(subjectId);
      })
      .catch((err) => {
        console.error("subjectNewSubject", err);

        if (err.value?.result?.messageText) toast.error(err.value?.result?.messageText);

        setTopBarProgress(false);
      });
  };

  const updateSubject = () => {
    console.log("subjectUpdateSubject start");
    setTopBarProgress(true);

    const body = {
      code: labelCode,
      privateEntity: {
        code: labelCode,
        firstName: labelFirstName,
        lastName: labelLastName,
      },
      contacts: [
        {
          contact: inputPhone,
          type: "P",
        },
        {
          contact: inputEmail,
          type: "E",
        },
      ],
      addresses: [
        {
          formattedAddress: inputAddress,
          type: "M",
        },
      ],
      bankAccount: {
        account: inputBankAccount,
        type: "IBAN",
      },
      card: {
        pan: inputCard,
      },
    };

    api
      .subjectUpdateSubject(sessionId, body)
      .finally(() => console.log("subjectUpdateSubject Promise is ready"))
      .then((data) => {
        console.log("subjectUpdateSubject data", data);
        //toast.success("Atnaujinta anketa");

        const subjectId = data.subjectId;
        setSubjectId(subjectId);
        //console.warn("subjectId", subjectId);

        if (subjectId) checkContract(subjectId);
      })
      .catch((err) => {
        console.error("subjectUpdateSubject", err);

        if (err.value?.result?.messageText) toast.error(err.value?.result?.messageText);

        setTopBarProgress(false);
      });
  };

  const checkSignContract = (token) => {
    console.log("checkSignContract start");
    setTopBarProgress(true);

    api
      .checkSignContract(sessionId, token)
      .finally(() => console.log("checkSignContract Promise is ready"))
      .then((data) => {
        console.log("checkSignContract data", data);
        setTopBarProgress(false);

        const message =
          "Sveiki, " +
          labelFirstName +
          ", sutartis sėkmingai pasirašyta ir sukurta paskyra. Galite pradėti naudotis <strong>my tips </strong> paslauga, klientų savo noru paliekamų Arbatpinigių mokėjimo kortele pervedimas į Paslaugos gavėjo asmeninę banko sąskaitą. ";
        setSignContext(message, "success");
        //toast.success(message);

        // go to Dashboard
        navigate("/");
        //setLabelSingCode("");
      })
      .catch((err) => {
        console.warn("checkSignContract", err);

        if (err == Config.REST_RESULTCODE.WAITING) {
          console.log("checkSignContract setTimeout");

          setTimeout(function () {
            checkSignContract(token);
          }, 2500);
          //} else if (Config.REST_RESULTCODE.REJECTED) {
        } else {
          if (err.value?.result?.messageText) toast.error(err.value?.result?.messageText);

          setLabelSingCode("");
          setTopBarProgress(false);
        }
      });
  };

  const signContract = (body) => {
    console.log("signContract start");
    setTopBarProgress(true);

    api
      .signContract(sessionId, body)
      .finally(() => console.log("signContract Promise is ready"))
      .then((data) => {
        console.log("signContract data", data);
        toast.success("Sutarties pasirašymas");

        if (data.controlCode && data.token) {
          toast.success(
            "Kontrolinis kodas išsiųstas į jūsų įrenginį:" + data.controlCode
          );

          setLabelSingCode(data.controlCode);

          checkSignContract(data.token);
        } else {
          setTopBarProgress(false);
          toast.error("controlCode: " + data.controlCode);
        }
      })
      .catch((err) => {
        console.error("signContract", err);

        if (err.value?.result?.messageText) toast.error(err.value?.result?.messageText);

        if (err.result?.messageText) toast.error(err.result?.messageText);

        setTopBarProgress(false);
      });
  };

  const authInfo = () => {
    console.log("authAuthInfo start");
    setTopBarProgress(true);

    api
      .authAuthInfo(sessionId)
      .finally(() => console.log("authAuthInfo Promise is ready"))
      .then((data) => {
        console.log("authAuthInfo data", data);
        //toast.success("authAuthInfo");

        const authChannelCode = data.authSession?.authChannelCode;
        const code = data.authSession?.code;
        const phone = data.authSession?.phone;

        if (authChannelCode) {
          const signType = authChannelCode;
          const signData = {
            code: code,
            phone: phone,
            country: "LT",
            timestamp: true,
            language: "LT",
          };
          //console.warn("subjectId", subjectId);
          const body = {
            subjectId: subjectId,
            signType: signType,
            signData: signData,
            contractId: null,
          };

          console.log(">>>>> signContract body", body);

          signContract(body);
        } else {
          toast.error("authAuthInfo authChannelCode is NULL");
        }
      })
      .catch((err) => {
        console.error("authAuthInfo", err);

        setTopBarProgress(false);
      });
  };

  const downloadContract = (event) => {
    event.preventDefault();

    console.log("downloadContract start");
    console.log("contractContract start");

    const subjectId = user.subject.subject.subjectId;

    if (subjectId)
      api
        .contractContract(sessionId, subjectId)
        .finally(() => console.log("contractContract Promise is ready"))
        .then((data) => {
          console.log("contractContract data", data);

          if (data.document?.data) {
            const pdfBase64 = "data:application/pdf;base64," + data.document.data;
            //console.log(pdfBase64)

            triggerBase64Download(pdfBase64, "mytips-agreement");
          }
        })
        .catch((err) => {
          console.error("contractContract", err);
        });
  };

  const handleInputBankAccountChange = (event) => {
    //const result = event.target.value.replace(/[^0-9]/gi, "");
    const result = event.target.value.toUpperCase();

    setInputBankAccount(result);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    let validateFlag = true;

    // address test
    if (inputAddress.trim().length > 0) {
      setInputAddressInvalid("");
    } else {
      setInputAddressInvalid("is-invalid");
      validateFlag = false;

      toast.warning("Neteisingai nurodytas adresas");
    }

    // phone test
    const validPhone = new RegExp(/(\+3706)([0-9]{7})/, "");

    if (validPhone.test(inputPhone) && inputPhone.length <= 12) {
      setInputPhoneInvalid("");
    } else {
      setInputPhoneInvalid("is-invalid");
      validateFlag = false;

      toast.warning("Neteisingai nurodytas telefono numeris");
    }

    // email test
    const validEmail = new RegExp(/\b[\w.-]+@[\w.-]+\.\w{2,4}\b/, "");

    if (validEmail.test(inputEmail)) {
      setInputEmailInvalid("");
    } else {
      setInputEmailInvalid("is-invalid");
      validateFlag = false;

      toast.warning("Neteisingai nurodytas el. paštas");
    }

    // BA test
    const validBankAccount = new RegExp(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/, "");

    if (validBankAccount.test(inputBankAccount) && inputBankAccount.length <= 20) {
      setInputBankAccountInvalid("");
    } else {
      setInputBankAccountInvalid("is-invalid");
      validateFlag = false;

      toast.warning("Neteisingai nurodyta banko sąskaita");
    }

    // card test
    const validCard = new RegExp(/(\9440383600010)([0-9]{6})/, "");

    if (validCard.test(inputCard) && inputCard.length <= 19) {
      setInputCardInvalid("");
    } else {
      setInputCardInvalid("is-invalid");
      validateFlag = false;

      toast.warning("Neteisingai nurodyta my tips kortelė");
    }

    if (validateFlag) {
      if (user.subject) {
        console.log(">>>> start update subject");
        updateSubject();
      } else {
        console.log(">>>> start new subject");
        newSubject();
      }
    }
  };

  const onSingIn = (event) => {
    event.preventDefault();

    authInfo();
  };

  // countdown
  const Completionist = () => <span>(o_O)</span>;

  const rendererCountdown = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span className="btn btn-light btn-xl">
          {minutes}:{seconds}m
        </span>
      );
    }
  };

  return (
    <React.Fragment>
      {topBarProgress && <TopBarProgress />}
      <div className="content">
        <div className="container">
          {!user.subject && !user.sign && (
            <ul className="steps">
              <li className={document ? "step step-success" : "step step-active"}>
                <div className="step-content">
                  <span className="step-circle">1</span>
                  <span className="step-text">Duomenys</span>
                </div>
              </li>
              <li className={document ? "step step-active" : "step"}>
                <div className="step-content">
                  <span className="step-circle">2</span>
                  <span className="step-text">Sutartis</span>
                </div>
              </li>
              <li className="step">
                <div className="step-content">
                  <span className="step-circle">3</span>
                  <span className="step-text">Paskyra</span>
                </div>
              </li>
            </ul>
          )}

          {!document ? (
            <form onSubmit={onSubmit}>
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">
                    <i className="fas fa-id-card mr-1"></i> Paslaugos gavėjo anketa
                  </h3>
                </div>

                <div className="card-body">
                  {user.subject && (
                    <div className="row">
                      <div className="col-md-12">
                        <p>
                          <i>
                            Atnaujintą paslaugos gavėjo anketą būtina pasirašyti paspaudus
                            mygtuką apačioje <strong>Sudaryti sutartį</strong>.
                          </i>
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Vardas</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={labelFirstName}
                          disabled
                          value={labelFirstName}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Pavardė</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={labelLastName}
                          disabled
                          value={labelLastName}
                        ></input>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Asmens kodas</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="{labelCode}"
                          disabled
                          value={labelCode}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Mokesčių mokėtojo identifikacinis numeris</label>

                        <input
                          type="text"
                          className="form-control"
                          placeholder="00000000000"
                          disabled
                          value={labelCode}
                        ></input>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Adresas</label>
                        <input
                          type="text"
                          className={`form-control ${inputAddressInvalid}`}
                          placeholder="Kareivių g. 2, Vilnius, Lietuva"
                          value={inputAddress}
                          onInput={(e) => setInputAddress(e.target.value)}
                        ></input>

                        <span className="error invalid-feedback">
                          Neteisingai nurodytas adresas
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Telefono numeris</label>
                        <input
                          type="text"
                          className={`form-control ${inputPhoneInvalid}`}
                          placeholder="+37060000000"
                          value={inputPhone}
                          onInput={(e) => setInputPhone(e.target.value)}
                        ></input>

                        <span className="error invalid-feedback">
                          Neteisingai nurodytas telefono numeris
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>El. pašto adresas</label>
                        <input
                          type="text"
                          className={`form-control ${inputEmailInvalid}`}
                          placeholder="test@test.com"
                          value={inputEmail}
                          onInput={(e) => setInputEmail(e.target.value)}
                        ></input>

                        <span className="error invalid-feedback">
                          Neteisingai nurodytas el. paštas
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Banko sąskaitos numeris</label>

                        <input
                          type="text"
                          className={`form-control ${inputBankAccountInvalid}`}
                          placeholder="LT012345678901234567"
                          value={inputBankAccount}
                          onInput={(e) => setInputBankAccount(e.target.value)}
                          onChange={handleInputBankAccountChange}
                        ></input>

                        <span className="error invalid-feedback">
                          Neteisingai nurodyta banko sąskaita
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>My tips kortelės numeris</label>

                        <input
                          type="text"
                          className={`form-control ${inputCardInvalid}`}
                          placeholder="9440383600010000000"
                          value={inputCard}
                          onInput={(e) => setInputCard(e.target.value)}
                          disabled={`${
                            user.subject && user.subject.card ? "disabled" : ""
                          }`}
                        ></input>

                        <span className="error invalid-feedback">
                          Neteisingai nurodytas <strong>my tips</strong> kortelės numeris
                        </span>
                      </div>
                    </div>

                    {user.subject && (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Pasirašyta sutartis</label>
                          <br />
                          <a
                            type=""
                            className=""
                            onClick={downloadContract}
                            role="presentation"
                          >
                            Peržiūrėti ir atsiųsti
                          </a>
                        </div>

                        <div className="form-group">
                          <label>Kiti dokumentai</label>
                          <br />

                          <a
                            href={`${process.env.PUBLIC_URL}/share/BENDROSIOS_SALYGOS.pdf`}
                            target="_black"
                          >
                            Mokėjimų pervedimų paslaugų bendrosios sąlygos
                          </a>
                          <br />

                          <a
                            href={`${process.env.PUBLIC_URL}/share/KAINORASTIS.pdf`}
                            target="_black"
                          >
                            Kainoraštis
                          </a>
                        </div>
                      </div>
                    )}
                  </div>

                  {!user.subject && (
                    <React.Fragment>
                      <div className="form-group">
                        {checkboxes.map(({ label, value }, index) => {
                          return (
                            <div className="form-check" key={index}>
                              <p>
                                <small>
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`checkbox${index}`}
                                    checked={checkedState[index]}
                                    onChange={() => handleOnChange(index)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`checkbox${index}`}
                                  >
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: label,
                                      }}
                                    />
                                  </label>
                                </small>
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </React.Fragment>
                  )}
                </div>
                <div className="card-footer clearfix">
                  {user.subject ? (
                    <React.Fragment>
                      <Link to="/" className="btn btn-info">
                        Atgal
                      </Link>

                      <button type="submit" className="btn btn-success float-right">
                        Sudaryti sutartį
                      </button>
                    </React.Fragment>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-success float-right"
                      disabled={!total}
                    >
                      Sudaryti sutartį
                    </button>
                  )}
                </div>
              </div>
            </form>
          ) : (
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">
                  <i className="fas fa-id-card mr-1"></i> Sutartis
                </h3>
              </div>
              <div className="card-body col">
                {labelSingCode ? (
                  <div className="text-center">
                    <p>Kontrolinis kodas išsiųstas į jūsų įrenginį</p>
                    <h1 className="alert alert-light text-xl">{labelSingCode}</h1>
                    <p>
                      Jūsų kontrolinio kodo galiojimas baigsis už{" "}
                      <Countdown
                        date={Date.now() + 119000}
                        renderer={rendererCountdown}
                      />
                    </p>
                    <p>
                      <small>
                        Jei aukščiau pavaizduotas kodas sutampa su kodu, kurį matote
                        telefono ekrane, įveskite savo sPIN2 kodą.
                      </small>
                    </p>
                  </div>
                ) : (
                  <PdfViewer document={document} />
                )}
              </div>

              <div className="card-footer clearfix">
                <button
                  type="button"
                  className="btn btn-success float-right"
                  onClick={onSingIn}
                  disabled={labelSingCode ? true : ""}
                >
                  Sudaryti sutartį
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Profile;
