import React, { ReactElement } from "react";
import { formatDate } from "src/shared/utils/dateFormatter";

type TileAmountProps = {
  amount: number;
  date: string;
};

export const TileAmount = ({ amount, date }: TileAmountProps): ReactElement => {
  return (
    <span className="info-box-text font-weight-bold">
      {amount}€ <small className="text-muted">(nuo {formatDate(date)})</small>
    </span>
  );
};
