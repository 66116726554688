import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useUserContext } from "src/shared/hooks";
import api from "../../api/helperPromise";
import { format } from "date-fns";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import lt from "date-fns/locale/lt";

import DataTable from "react-data-table-component";
import { ShowButtonBlock } from "../base/ShowButtonBlock";
import {
  CustomTablePagination,
  CustomTableFooter,
  FooterBlock,
} from "../base/CustomTablePagination";

import { formatCurrency } from "src/shared/utils";

registerLocale("lt", lt);

const columns = [
  {
    name: "#",
    selector: (row) => row.id,
    sortable: true,
    compact: true,
    minWidth: "2%",
    maxWidth: "4%",
  },
  {
    name: "Prekybininkas",
    selector: (row) => row.subjectName,
    sortable: true,
  },
  {
    name: "Adresas",
    selector: (row) => row.address,
    sortable: true,
    wrap: true,
  },
  {
    name: "Pervestų arbatpinigių suma",
    selector: (row) => row.amount,
    format: (row) => formatCurrency(row.amount, row.currency),
    sortable: true,
    right: true,
    maxWidth: "200px",
  },
  {
    name: "Data",
    selector: (row) => row.transactionValueDate,
    sortable: true,
    maxWidth: "150px",
    center: true,
  },
];

function Stats() {
  const [dataItems, setDataItems] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [amountTotal, setAmountTotal] = useState();

  // context
  const { user } = useUserContext();

  const CustomPagination = useCallback(
    (props) => {
      const amount =
        dataItems.length > 0
          ? dataItems.reduce((amount, row) => amount + row.amount, 0)
          : 0;
      const currencyCode = dataItems.length > 0 ? dataItems[0].currency : null;

      return (
        <CustomTablePagination {...props}>
          <CustomTableFooter>
            <FooterBlock compact={true}>Viso:</FooterBlock>
            <FooterBlock empty={true} couldGrow={true} />
            <FooterBlock align="right" width="250px">
              <span className="mobile-only mr-1">Suma:</span>{" "}
              {formatCurrency(amount, currencyCode)}
            </FooterBlock>
            <FooterBlock align="center" width="150px">
              Kiekis: {dataItems.length}
            </FooterBlock>
          </CustomTableFooter>
        </CustomTablePagination>
      );
    },
    [dataItems]
  );

  // chech if user
  if (!user || !user.subject) return;

  //return;
  const sessionId = user?.session?.sessionId;

  const financeTransaction = () => {
    console.log("financeTransaction start - stats.jsx");

    var startDateFormated = format(startDate, "yyyy-MM-dd");
    var endDateFormated = format(endDate, "yyyy-MM-dd");

    const body = {
      transactionDate: startDateFormated,
      transactionDateTo: endDateFormated,
    };

    api
      .financeTransaction(sessionId, body)
      .finally(() => console.log("financeTransaction Promise is ready"))
      .then((data) => {
        console.log("financeTransaction data", data);

        var newData = [];
        var amountTotal = 0;

        data.transactions.map((item, i) => {
          //console.log(item, i)

          amountTotal += item.amount.amount;

          var date = new Date(item.transactionValueDate);

          const tempData = {
            id: i + 1,
            subjectName: item.debitorAccount.subjectName,
            address: item.debitorAccount.address,
            amount: item.amount.amount,
            currency: item.amount.currency,
            transactionValueDate: format(date, "yyyy-MM-dd"),
            name: item.name,
          };

          //console.log(tempData);
          newData.push(tempData);
        });

        setAmountTotal(Math.round(amountTotal * 100) / 100);
        setDataItems(newData);
      })
      .catch((err) => {
        console.log("financeTransaction", err);
      });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    financeTransaction();
  };

  return (
    <div className="content">
      <div className="container">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-thumbs-up mr-1"></i> Perversti arbatpinigiai
            </h3>
          </div>
          <div className="card-body">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Data nuo</label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      maxDate={endDate}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      locale="lt"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Data iki</label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      locale="lt"
                    />
                  </div>
                </div>
                <ShowButtonBlock text="Rodyti" />
              </div>
            </form>

            {dataItems && (
              <DataTable
                columns={columns}
                data={dataItems}
                noDataComponent="Nėra duomenų"
                paginationComponent={CustomPagination}
                pagination
              />
            )}

            {/*dataItems && (
              <p className="float-right">
                Arbatpinigių suma už periodą: <strong>{amountTotal} EUR</strong>
              </p>
            )*/}
          </div>

          <div className="card-footer clearfix">
            <Link to="/" className="btn btn-info">
              Atgal
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stats;
