import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useUserContext, useSession, useSignOut } from "src/shared/hooks";

function Session() {
  // context
  const { user } = useUserContext();
  const { sessionGet, sessionCheck } = useSession();
  const { signOut } = useSignOut();

  //console.clear();
  const location = useLocation();

  // check if session: workaround
  console.log("session user >>>>>>>>>>", user);

  useEffect(() => {
    // localStorage logic
    const session = localStorage.getItem("session");
    let sessionObj = false;

    // parse saved data
    try {
      sessionObj = JSON.parse(session);
    } catch (e) {
      console.log("session in not an OBJECT: ", session, e);
    }

    if (sessionObj?.sessionId && !user) {
      console.log("localStorage sessionObj", sessionObj);
      sessionGet(sessionObj.sessionId);
    }

    // if session is ok
    if (user && user.session) {
      if (user.login) {
        sessionCheck(user.session.sessionId).catch(() => signOut());
      }
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (user && user.session) {
      sessionCheck(user.session.sessionId).catch(() => signOut());
    }
  }, [location.pathname]); // eslint-disable-line

  useEffect(() => {
    // localStorage set
    const session = user?.session;
    if (session) {
      localStorage.setItem("session", JSON.stringify(session));
    } else {
      localStorage.removeItem("session");
    }
    console.log("Session in local storage updated");
  }, [user?.session]); // eslint-disable-line

  //if (!sessionData) return <div>Loading session data...</div>;

  //return <div>{JSON.stringify(user)}</div>;
}

export default Session;
