export const defaultPath = "/";
export const profilePath = "Profile";
export const aboutPath = "About";
export const contactPath = "Contact";
export const faqPath = "Faq";
export const cardsPath = "Cards";
export const statsPath = "Stats";
export const pdfPath = "Pdf";
export const cookiesPolicyPath = "CookiesPolicy";
export const subjectStatsPath = "SubjectStats";
export const uploaderPath = "Uploader";
export const acquirerTransactionsPath = "AcquirerTransactions";
export const paymentDocumentListPath = "PaymentDocumentList";
export const bankTransactionsPath = "BankTransactions";

export default {
  default: defaultPath,
  profile: profilePath,
  about: aboutPath,
  contact: contactPath,
  faq: faqPath,
  cards: cardsPath,
  stats: statsPath,
  pdf: pdfPath,
  cookiesPolicy: cookiesPolicyPath,
  subjectStats: subjectStatsPath,
  uploader: uploaderPath,
  acquirerTransactions: acquirerTransactionsPath,
  paymentDocumentList: paymentDocumentListPath,
  bankTransactions: bankTransactionsPath,
};
