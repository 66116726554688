import React, { MouseEventHandler } from "react";

type ButtonEditProps = {
  editHandler: MouseEventHandler<HTMLButtonElement>;
  row: any; // eslint-disable-line
};

export const ButtonEdit = ({ editHandler, row }: ButtonEditProps) => (
  <button
    className="btn btn-warning btn-sm"
    onClick={editHandler}
    value={`${row.transactionId}`}
  >
    Redaguoti
  </button>
);
