import { BaseResponse } from "src/shared/types/BaseResponse";
import { DownloadFileResponse, UploadFileRequest } from "src/shared/types/files";
import { ResponseWrapper } from "src/shared/types/ResponseWrapper";
import Config from "../components/Config/Config";

class apiFile {
  fileUpload = (sessionId: string, body: FormData): Promise<BaseResponse> => {
    return new Promise((resolve, reject) => {
      const requestOptions: RequestInit = {
        method: Config.REST_API.apiFileUpload.METHOD,
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
        body: body,
      };

      fetch(Config.REST_URL + Config.REST_API.apiFileUpload.PATH, requestOptions)
        .then((res) => {
          console.warn(res);
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data) => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  fileDownload = (
    sessionId: string,
    documentId: string
  ): Promise<DownloadFileResponse> => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiFileDownload.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
      };

      fetch(
        Config.REST_URL +
          Config.REST_API.apiFileDownload.PATH +
          "?documentId=" +
          documentId,
        requestOptions
      )
        .then((res) => {
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<DownloadFileResponse>): void => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  fileDownloadSepa = (
    sessionId: string,
    documentId: string
  ): Promise<DownloadFileResponse> => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiFileDownloadSepa.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
      };

      fetch(
        Config.REST_URL +
          Config.REST_API.apiFileDownloadSepa.PATH +
          "?documentId=" +
          documentId,
        requestOptions
      )
        .then((res) => {
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<DownloadFileResponse>): void => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export default apiFile;
