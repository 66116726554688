import React, { Component } from "react";

class Footer extends Component {
  state = {};
  render() {
    return <React.Fragment>2022 © Visos teisės saugomos.</React.Fragment>;
  }
}

export default Footer;
