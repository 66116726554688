import React, { ReactElement } from "react";

type DashboardTileProps = {
  icon: string;
  children?: ReactElement;
};

export const DashboardTile = ({ icon, children }: DashboardTileProps) => {
  return (
    <div className="col-md-4 col-sm-6 col-12">
      <div className="info-box">
        <span className="info-box-icon">
          <i className={icon}></i>
        </span>
        <div className="info-box-content">{children}</div>
      </div>
    </div>
  );
};
