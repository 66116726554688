import apiAuth from "./auth";
import apiSession from "./session";
import apiSubject from "./subject";
import apiContract from "./contract";
import apiFile from "./file";
import apiFinance from "./finance";
import apiMonitoring from "./monitoring";
import apiDocument from "./document";
import apiCard from "./card";
import {
  GetContractResponse,
  SignContractRequest,
  SignContractResponse,
  SignContractStatusResponse,
} from "src/shared/types/contracts";
import { BaseResponse } from "src/shared/types/BaseResponse";
import { SessionResponse } from "src/shared/types/sessions";
import { AuthInfoResponse, AuthStatusResponse } from "src/shared/types/auth";
import { AuthRequest } from "src/shared/types/auth/requests";
import { AuthDataResponse } from "src/shared/types/auth/responses/AuthDataResponse";
import { CardActionRequest, CardsResponse } from "src/shared/types/cards";
import { DocumentListRequest, DocumentListResponse } from "src/shared/types/documents";
import { DownloadFileResponse } from "src/shared/types/files";
import {
  AcquirerTotalTransactionsRequest,
  AcquirerTotalTransactionsResponse,
  AcquirerTransactionsRequest,
  AcquirerTransactionsResponse,
  AddTransactionRequest,
  BankTransactionsRequest,
  BankTransactionsResponse,
  PaymentDocumentListRequest,
  PaymentDocumentListResponse,
  TransactionRequest,
  TransactionsResponse,
  TransactionsSummaryResponse,
  TransactionSummaryRequest,
  UpdateTransactionRequest,
} from "src/shared/types/finances";
import { UserActionsRequest, UserActionsResponse } from "src/shared/types/monitoring";
import {
  SubjectOperationResponse,
  SubjectRequest,
  SubjectResponse,
} from "src/shared/types/subjects";

type apiHelperState = {
  session: apiSession;
  auth: apiAuth;
  subject: apiSubject;
  contract: apiContract;
  file: apiFile;
  finance: apiFinance;
  monitoring: apiMonitoring;
  document: apiDocument;
  card: apiCard;
};

class apiHelper {
  state: apiHelperState;

  constructor() {
    console.log("API Helper Initialized");
    this.state = {
      session: new apiSession(),
      auth: new apiAuth(),
      subject: new apiSubject(),
      contract: new apiContract(),
      file: new apiFile(),
      finance: new apiFinance(),
      monitoring: new apiMonitoring(),
      document: new apiDocument(),
      card: new apiCard(),
    };
  }

  // auth
  authAuthenticate = (
    sessionId: string,
    body: AuthRequest
  ): Promise<AuthDataResponse> => {
    return this.state.auth.authAuthenticate(sessionId, body);
  };

  authAuthenticationStatus = (
    sessionId: string,
    accessToken: string
  ): Promise<AuthStatusResponse> => {
    return this.state.auth.authAuthenticationStatus(sessionId, accessToken);
  };

  authAuthInfo = (sessionId: string): Promise<AuthInfoResponse> => {
    return this.state.auth.authAuthInfo(sessionId);
  };

  authLogout = (sessionId: string): Promise<string> => {
    return this.state.auth.authLogout(sessionId);
  };

  // card
  cardCards = (sessionId: string, subjectId: string): Promise<CardsResponse> => {
    return this.state.card.cardCards(sessionId, subjectId);
  };
  disableCard = (sessionId: string, body: CardActionRequest): Promise<BaseResponse> => {
    return this.state.card.disableCard(sessionId, body);
  };

  // contract
  contractContract = (
    sessionId: string,
    subjectId: string
  ): Promise<GetContractResponse> => {
    return this.state.contract.contractContract(sessionId, subjectId);
  };
  signContract = (
    sessionId: string,
    body: SignContractRequest
  ): Promise<SignContractResponse> => {
    return this.state.contract.signContract(sessionId, body);
  };
  checkContract = (sessionId: string, subjectId: string): Promise<BaseResponse> => {
    return this.state.contract.checkContract(sessionId, subjectId);
  };
  checkSignContract = (
    sessionId: string,
    token: string
  ): Promise<SignContractStatusResponse> => {
    return this.state.contract.checkSignContract(sessionId, token);
  };

  // document
  documentDocumentList = (
    sessionId: string,
    body: DocumentListRequest
  ): Promise<DocumentListResponse> => {
    return this.state.document.documentDocumentList(sessionId, body);
  };

  // file
  fileUpload = (sessionId: string, body: FormData): Promise<BaseResponse> => {
    return this.state.file.fileUpload(sessionId, body);
  };
  fileDownload = (
    sessionId: string,
    documentId: string
  ): Promise<DownloadFileResponse> => {
    return this.state.file.fileDownload(sessionId, documentId);
  };
  fileDownloadSepa = (
    sessionId: string,
    documentId: string
  ): Promise<DownloadFileResponse> => {
    return this.state.file.fileDownloadSepa(sessionId, documentId);
  };

  // finance
  financeTransaction = (
    sessionId: string,
    body: TransactionRequest
  ): Promise<TransactionsResponse> => {
    return this.state.finance.financeTransaction(sessionId, body);
  };
  financeTransactionSummary = (
    sessionId: string,
    body: TransactionSummaryRequest
  ): Promise<TransactionsSummaryResponse> => {
    return this.state.finance.financeTransactionSummary(sessionId, body);
  };
  financeBankTransactions = (
    sessionId: string,
    body: BankTransactionsRequest
  ): Promise<BankTransactionsResponse> => {
    return this.state.finance.financeBankTransactions(sessionId, body);
  };
  financeAcquirerTransactions = (
    sessionId: string,
    body: AcquirerTransactionsRequest
  ): Promise<AcquirerTransactionsResponse> => {
    return this.state.finance.financeAcquirerTransactions(sessionId, body);
  };
  financeAcquirerTotalTransactions = (
    sessionId: string,
    body: AcquirerTotalTransactionsRequest
  ): Promise<AcquirerTotalTransactionsResponse> => {
    return this.state.finance.financeAcquirerTotalTransactions(sessionId, body);
  };
  financePaymentDocumentList = (
    sessionId: string,
    body: PaymentDocumentListRequest
  ): Promise<PaymentDocumentListResponse> => {
    return this.state.finance.financePaymentDocumentList(sessionId, body);
  };
  financeAddTransaction = (
    sessionId: string,
    body: AddTransactionRequest
  ): Promise<BaseResponse> => {
    return this.state.finance.financeAddTransaction(sessionId, body);
  };
  financeUpdateTransaction = (
    sessionId: string,
    body: UpdateTransactionRequest
  ): Promise<BaseResponse> => {
    return this.state.finance.financeUpdateTransaction(sessionId, body);
  };

  // monitoring
  monitoringUserActions = (
    sessionId: string,
    body: UserActionsRequest
  ): Promise<UserActionsResponse> => {
    return this.state.monitoring.monitoringUserActions(sessionId, body);
  };

  // session
  sessionGet = (sessionId: string): Promise<SessionResponse> => {
    return this.state.session.sessionGet(sessionId);
  };
  sessionOpen = (): Promise<SessionResponse> => {
    return this.state.session.sessionOpen();
  };
  sessionRefresh = (sessionId: string): Promise<SessionResponse> => {
    return this.state.session.sessionRefresh(sessionId);
  };

  // subject
  subjectGetSubject = (
    sessionId: string,
    code: string,
    type: string
  ): Promise<SubjectResponse> => {
    return this.state.subject.subjectGetSubject(sessionId, code, type);
  };
  subjectNewSubject = (
    sessionId: string,
    body: SubjectRequest
  ): Promise<SubjectOperationResponse> => {
    return this.state.subject.subjectNewSubject(sessionId, body);
  };
  subjectUpdateSubject = (
    sessionId: string,
    body: SubjectRequest
  ): Promise<SubjectOperationResponse> => {
    return this.state.subject.subjectUpdateSubject(sessionId, body);
  };
}

const api = new apiHelper();
export default api;
