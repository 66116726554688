import React, { MouseEvent, ReactElement, useCallback } from "react";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import "./styles.css";

type TablePaginationActionsProps = {
  rowsPerPage: number;
  count: number;
  onPageChange: (event: MouseEvent<HTMLButtonElement> | null, page: number) => void;
  page: number;
  children?: ReactElement | null;
};

const getNumberOfPages = (count: number, rowsPerPage: number) => {
  return Math.ceil(count / rowsPerPage);
};

export const TablePaginationActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
  children,
}: TablePaginationActionsProps) => {
  const handleFirstPageButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onPageChange(e, 1);
    },
    [onPageChange]
  );

  // RDT uses page index starting at 1, MUI starts at 0
  // i.e. page prop will be off by one here
  const handleBackButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onPageChange(e, page);
    },
    [onPageChange, page]
  );

  const handleNextButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onPageChange(e, page + 2);
    },
    [onPageChange, page]
  );

  const handleLastPageButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onPageChange(e, getNumberOfPages(count, rowsPerPage));
    },
    [onPageChange, count, rowsPerPage]
  );

  return (
    <div className="pagination-actions-container">
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      {children}
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};
