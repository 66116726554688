import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import "react-pdf/dist/esm/Page/TextLayer";
import "react-pdf/dist/esm/Page/AnnotationLayer";

import "./styles.css";

function PdfViewer(props) {
  let PdfBase64 = "data:application/pdf;";
  //console.log(props.document)
  //PdfBase64 += PdfExample();
  PdfBase64 += "base64," + props.document;
  //console.log(PdfBase64);

  // context
  //const { user, setUser } = useContext(UserContext);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  function onDocumentLoadProgress({ loaded, total }) {
    let percent = loaded / total;
    if (percent > 1) percent = 1;
    console.log("Loading a document: " + percent * 100 + "%");
  }

  const onClickPrev = () => {
    let currentPage = pageNumber;
    if (currentPage > 1) currentPage--;
    setPageNumber(currentPage);
  };

  const onClickNext = () => {
    let currentPage = pageNumber;
    if (currentPage < numPages) currentPage++;
    setPageNumber(currentPage);
  };

  //  console.log(Document);

  return (
    <React.Fragment>
      <div className="row pdfDialog">
        <div className="col-6 text-right">
          <div className="btn-group">
            <button type="button" className="btn btn-default" onClick={onClickPrev}>
              Atgal
            </button>
            <button type="button" className="btn btn-default" onClick={onClickNext}>
              Pirmyn
            </button>
          </div>
        </div>
        <div className="col-6">
          <p className="py-2">
            Puslapis {pageNumber} iš {numPages}
          </p>
        </div>
      </div>

      <Document
        file={PdfBase64}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadProgress={onDocumentLoadProgress}
        className="pdf-document"
      >
        <Page pageNumber={pageNumber} />
      </Document>
    </React.Fragment>
  );
}

export default PdfViewer;
