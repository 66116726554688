import React from "react";

function About() {
  return (
    <div className="content">
      <div className="container">
        <h2>Apie</h2>

        <div className="card">
          <div className="card-body row">
            <div className="col-sm-6 d-flex align-items-center justify-content">
              <div className="">
                <h3>
                  Arbatpinigiai banko kortele <strong>my tips</strong>
                </h3>

                <p>
                  „ASHBURN International“ pristato išskirtinį būdą palikti arbatpinigius
                  be grynųjų – mokėjimo kortelių skaitytuvu be jokios papildomos įrangos.
                  Vienas mostas kortele mokėjimo kortelių skaitytuvo link suteiks galimybę
                  klientui apmokėti sąskaitą ir kartu palikti arbatpinigius
                  aptarnaujančiam personalui.
                </p>

                <h3>Nauda aptarnaujančiam personalui</h3>
                <ul>
                  <li>
                    Galimybė teisėtai gauti arbatpinigius tiesiogiai į savo asmeninę banko
                    sąskaitą iš klientų, kurie neturi grynųjų.
                  </li>
                  <li>
                    Greita ir patogi registracija my tips savitarnos sistemoje. Po
                    registracijos galima pradėti dirbti iš karto, taigi padavėjas gali
                    užsiregistruoti net darbo vietoje (išmaniuoju telefonu).
                  </li>
                  <li>Patogus, greitas ir saugus papildomo uždarbio būdas.</li>
                  <li>
                    Galimybė dirbti keliose maitinimo įstaigose, kuriose mokėjimo kortelių
                    skaitytuvai atlieka arbatpinigių surinkimo funkciją.
                  </li>
                </ul>

                <h3>Nauda maitinimo įstaigai</h3>
                <ul>
                  <li>
                    Galimybė pasiūlyti savo klientams patogų atsiskaitymą ir atsidėkojimą
                    arbatpinigiais be grynųjų pinigų.
                  </li>
                  <li>
                    Nereikia rūpintis papildoma įranga, nes arbatpinigių surinkimo
                    funkciją atlieka mokėjimo kortelių skaitytuvas.
                  </li>
                  <li>Nereikia rūpintis papildomais administravimo darbais.</li>
                  <li>
                    Skatinamas darbuotojų lojalumas, arbatpinigių dėka didinant jų darbo
                    užmokestį.
                  </li>
                  <li>Kuriama patrauklesnė darbo vieta naujiems darbuotojams.</li>
                  <li>
                    Pagerėja personalo darbo kokybė, nes padavėjai suinteresuoti gauti
                    arbatpinigių.
                  </li>
                </ul>

                <h3>Nauda klientui</h3>
                <ul>
                  <li>
                    Galimybė atsidėkoti aptarnaujančiam personalui paliekant arbatpinigių
                    banko kortele, kai neturi grynųjų pinigų.
                  </li>
                  <li>
                    Patogus ir greitas atsiskaitymas vienu mokėjimo kortelės prisilietimu.
                  </li>
                  <li>
                    Patogu, kad ir susimokėti už maistą, ir palikti arbatpinigių galima iš
                    vieno įrenginio.
                  </li>
                  <li>Klientas gali pats nustatyti norimą arbatpinigių sumą.</li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 text-center">
              <img
                className="img-fluid"
                alt="about"
                src={process.env.PUBLIC_URL + "/about.png"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
