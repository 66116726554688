const Config = {
  //REST_URL: "http://192.168.177.150:5000",

  REST_URL:
    process.env.NODE_ENV !== "development"
      ? "https://tips.ashburn.eu"
      : "http://192.168.10.20:5000", //prod
  //REST_URL: "http://192.168.177.139:5000", // anton
  //REST_URL: "http://192.168.10.20:5000",
  //REST_URL: "http://192.168.178.1:5000", // gedas
  //REST_URL: "https://tips.ashburn.eu",
  REST_API: {
    apiAuthAuthenticate: {
      PATH: "/api/Auth/authenticate",
      METHOD: "PUT",
    },
    apiAuthAuthenticationStatus: {
      PATH: "/api/Auth/authenticationStatus",
      METHOD: "GET",
    },
    apiAuthLogout: {
      PATH: "/api/Auth/logout",
      METHOD: "DELETE",
    },
    apiAuthAuthInfo: {
      PATH: "/api/Auth/authInfo",
      METHOD: "GET",
    },

    apiCardCards: {
      PATH: "/api/Card/Cards",
      METHOD: "GET",
    },
    apiCardDisableCard: {
      PATH: "/api/Card/disableCard",
      METHOD: "POST",
    },

    apiContractContract: {
      PATH: "/api/Contract/contract",
      METHOD: "PUT",
    },
    apiContractSignContract: {
      PATH: "/api/Contract/signContract",
      METHOD: "PUT",
    },
    apiContractCheckContract: {
      PATH: "/api/Contract/checkContract",
      METHOD: "GET",
    },
    apiContractCheckSignContract: {
      PATH: "/api/Contract/checkSignContract",
      METHOD: "GET",
    },

    apiDocumentDocumentList: {
      PATH: "/api/Document/documentList",
      METHOD: "POST",
    },

    apiFileUpload: {
      PATH: "/api/File/upload",
      METHOD: "POST",
    },
    apiFileDownload: {
      PATH: "/api/File/download",
      METHOD: "GET",
    },
    apiFileDownloadSepa: {
      PATH: "/api/File/downloadSepa",
      METHOD: "GET",
    },

    apiFinanceTransaction: {
      PATH: "/api/Finance/transaction",
      METHOD: "POST",
    },
    apiFinanceTransactionSummary: {
      PATH: "/api/Finance/transactionSummary",
      METHOD: "POST",
    },
    apiFinanceBankTransactions: {
      PATH: "/api/Finance/bankTransactions",
      METHOD: "POST",
    },
    apiFinanceAcquirerTransactions: {
      PATH: "/api/Finance/acquirerTransactions",
      METHOD: "POST",
    },
    apiFinanceAcquirerTotalTransactions: {
      PATH: "/api/Finance/acquirerTotalTransactions",
      METHOD: "POST",
    },
    apiFinancePaymentDocumentList: {
      PATH: "/api/Finance/paymentDocumentList",
      METHOD: "POST",
    },
    apiFinanceAddTransaction: {
      PATH: "/api/Finance/addTransaction",
      METHOD: "POST",
    },
    apiFinanceUpdateTransaction: {
      PATH: "/api/Finance/updateTransaction",
      METHOD: "POST",
    },

    apiMonitoringUserActions: {
      PATH: "/api/Monitoring/userActions",
      METHOD: "POST",
    },

    apiSessionOpen: {
      PATH: "/api/Session/open",
      METHOD: "POST",
    },
    apiSessionGet: {
      PATH: "/api/Session/get",
      METHOD: "GET",
    },
    apiSessionRefresh: {
      PATH: "/api/Session/refresh",
      METHOD: "POST",
    },

    apiSubjectGetSubject: {
      PATH: "/api/Subject/getSubject",
      METHOD: "GET",
    },
    apiSubjectNewSubject: {
      PATH: "/api/Subject/newSubject",
      METHOD: "POST",
    },
    apiSubjectUpdateSubject: {
      PATH: "/api/Subject/updateSubject",
      METHOD: "PUT",
    },
  },
  ROLE: {
    WAITERROLE: "WAITERROLE",
    TIPSOPERATOR: "TIPSOPERATOR",
    TIPSACCOUNTANT: "TIPSACCOUNTANT",
  },
  REST_RESULTCODE: {
    OK: "000000",
    WAITING: "WAITINGRESPONSE",
    REJECTED: "ERROR",
    USERNOTFOUND: "200012",
  },
  ERROR: {
    RESULT_NOT_OK: "Result is not OK",
  },
};

export default Config;
