import React, { useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useUserContext } from "src/shared/hooks";
import { formatCurrency } from "src/shared/utils";
import api from "../../api/helperPromise";
import { format } from "date-fns";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import lt from "date-fns/locale/lt";
import { toast } from "react-toastify";
import {
  CustomTablePagination,
  CustomTableFooter,
  FooterBlock,
} from "src/components/base/CustomTablePagination";
import DataTable from "react-data-table-component";

import { ShowButtonBlock } from "../base/ShowButtonBlock";
import { ButtonEdit } from "./ButtonEdit";

registerLocale("lt", lt);

function BankTransactions() {
  const [dataItems, setDataItems] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [transactionDate, setTransactionDate] = useState(new Date());

  // context
  const { user } = useUserContext();

  // state
  const [transaction, setTransaction] = useState("");

  const [inputTransactionId, setInputTransactionId] = useState("");
  const [inputTransactionNo, setInputTransactionNo] = useState("");
  const [inputBeneficiaryAccount, setInputBeneficiaryAccount] = useState("");
  const [inputTransactionAmount, setInputTransactionAmount] = useState("");

  //return;
  const sessionId = user?.session?.sessionId;

  const bankTransaction = useCallback(
    (transactionId) => {
      console.log("bankTransaction start");

      var startDateFormated = format(startDate, "yyyy-MM-dd");
      var endDateFormated = format(endDate, "yyyy-MM-dd");

      const body = {
        transactionId: transactionId,
        dateFrom: startDateFormated,
        dateTo: endDateFormated,
      };

      api
        .financeBankTransactions(sessionId, body)
        .finally(() => console.log("bankTransaction Promise is ready"))
        .then((data) => {
          console.log("bankTransaction data", data);

          const transaction = data.bankTransactions[0];

          if (transaction) {
            setTransaction(transaction);

            setInputTransactionId(transaction.transactionId);
            setInputTransactionNo(transaction.transactionNo);
            setInputBeneficiaryAccount(transaction.beneficiaryAccount);
            setInputTransactionAmount(transaction.amount);

            var date = new Date(transaction.date);
            setTransactionDate(date);
          } else {
            setTransaction(null);
          }
        })
        .catch((err) => {
          console.log("bankTransaction", err);
        });
    },
    [
      startDate,
      endDate,
      sessionId,
      setTransaction,
      setInputTransactionId,
      setInputTransactionNo,
      setInputBeneficiaryAccount,
      setInputTransactionAmount,
      setTransactionDate,
    ]
  );

  const editHandler = useCallback(
    (event) => {
      const transactionId = event.target.value;

      if (transactionId) {
        console.log(transactionId);

        bankTransaction(transactionId);
      }
    },
    [bankTransaction]
  );

  // table
  const columns = useMemo(
    () => [
      {
        name: "Mokėtojo bankas",
        selector: (row) => row.OriginatorName,
        sortable: true,
        minWidth: "100px",
        maxWidth: "150px",
        compact: true,
      },
      {
        name: "Pavedimo numeris",
        selector: (row) => row.transactionNo,
        sortable: true,
        maxWidth: "150px",
        compact: true,
      },
      {
        name: "Gavėjas",
        selector: (row) => row.beneficiaryName,
        sortable: true,
        minWidth: "150px",
        compact: true,
        wrap: true,
      },
      {
        name: "Gavėjo sąskaita",
        selector: (row) => row.beneficiaryAccount,
        sortable: true,
        minWidth: "150px",
        compact: true,
      },
      {
        name: "Data",
        selector: (row) => row.date,
        sortable: true,
        maxWidth: "100px",
        compact: true,
      },

      {
        name: "Suma",
        selector: (row) => row.amount,
        format: (row) => formatCurrency(row.amount, row.currencyCode),
        sortable: true,
        maxWidth: "100px",
        right: true,
      },
      {
        name: "Būklė",
        selector: (row) => row.status,
        sortable: true,
        maxWidth: "150px",
        center: true,
      },
      {
        name: "Apdorojimo data",
        selector: (row) => row.processingDate,
        sortable: true,
        maxWidth: "100px",
        compact: true,
      },
      {
        name: "",
        button: true,
        selector: (row) => <ButtonEdit row={row} editHandler={editHandler} />,
        sortable: true,
        compact: true,
      },
    ],
    [editHandler]
  );

  const bankTransactions = useCallback(() => {
    console.log("bankTransactions start");

    var startDateFormated = format(startDate, "yyyy-MM-dd");
    var endDateFormated = format(endDate, "yyyy-MM-dd");

    const body = {
      dateFrom: startDateFormated,
      dateTo: endDateFormated,
    };

    api
      .financeBankTransactions(sessionId, body)
      .finally(() => console.log("bankTransactions Promise is ready"))
      .then((data) => {
        console.log("bankTransactions data", data);

        var newData = data.bankTransactions.map((item, i) => {
          //console.log(item, i)

          var date = new Date(item.date);
          let processingDateFormated = "Neapdorota";
          if (item.processingDate) {
            var processingDate = new Date(item.processingDate);
            processingDateFormated = format(processingDate, "yyyy-MM-dd");
          }

          const tempData = {
            id: i + 1,
            OriginatorCode: item.OriginatorCode,
            OriginatorName: item.OriginatorName,
            date: format(date, "yyyy-MM-dd"),
            processingDate: processingDateFormated,
            currencyCode: item.currencyCode,
            amount: item.amount,
            beneficiarCode: item.beneficiarCode,
            beneficiaryAccount: item.beneficiaryAccount,
            beneficiaryName: item.beneficiaryName,
            status: item.status,
            transactionId: item.transactionId,
            transactionNo: item.transactionNo,
          };

          //console.log(tempData);
          return tempData;
        });

        console.log(newData);
        setDataItems(newData);
      })
      .catch((err) => {
        console.log("bankTransactions", err);
      });
  }, [setDataItems, startDate, endDate, sessionId]);

  const addTransaction = useCallback(() => {
    console.log("addTransaction start");

    let date = format(transactionDate, "yyyy-MM-dd");

    const body = {
      transactionNumber: inputTransactionNo,
      account: inputBeneficiaryAccount,
      accountId: null,
      date: date,
      amount: parseFloat(inputTransactionAmount),
      currency: "EUR",
    };

    const createPromise = api.financeAddTransaction(sessionId, body);
    createPromise
      .finally(() => console.log("addTransaction Promise is ready"))
      .then((data) => {
        console.log("addTransaction data", data);
        toast.success("Naujas pervedimas");

        setTransaction(null);
      })
      .catch((err) => {
        console.log("addTransaction", err);

        if (err.value?.result?.messageText) toast.error(err.value?.result?.messageText);

        if (err.result?.messageText) toast.error(err.result?.messageText);
      });

    return createPromise;
  }, [
    setTransaction,
    inputBeneficiaryAccount,
    inputTransactionAmount,
    inputTransactionNo,
    sessionId,
    transactionDate,
  ]);

  const updateTransaction = useCallback(() => {
    console.log("updateTransaction start");

    let date = format(transactionDate, "yyyy-MM-dd");

    const body = {
      transactionId: inputTransactionId,
      transactionNumber: inputTransactionNo,
      account: inputBeneficiaryAccount,
      accountId: null,
      date: date,
      amount: parseFloat(inputTransactionAmount),
      currency: "EUR",
    };

    const updatePromise = api.financeUpdateTransaction(sessionId, body);
    updatePromise
      .finally(() => console.log("updateTransaction Promise is ready"))
      .then((data) => {
        console.log("updateTransaction data", data);
        toast.success("Atnaujintas pervedimas");

        setTransaction(null);
      })
      .catch((err) => {
        console.log("updateTransaction", err);

        if (err.value?.result?.messageText) toast.error(err.value?.result?.messageText);

        if (err.result?.messageText) toast.error(err.result?.messageText);
      });

    return updatePromise;
  }, [
    setTransaction,
    inputBeneficiaryAccount,
    inputTransactionAmount,
    inputTransactionId,
    inputTransactionNo,
    sessionId,
    transactionDate,
  ]);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setTransaction(null);

      bankTransactions();
    },
    [setTransaction, bankTransactions]
  );

  const onSubmitEdit = useCallback(
    (event) => {
      event.preventDefault();

      const promise = inputTransactionId ? updateTransaction() : addTransaction();
      promise.then(() => bankTransactions());
    },
    [updateTransaction, addTransaction, bankTransactions, inputTransactionId]
  );

  const showAddTransaction = useCallback(
    (event) => {
      event.preventDefault();

      setTransaction(true);

      setInputTransactionId("");
      setInputTransactionNo("");
      setInputBeneficiaryAccount("");
      setInputTransactionAmount("");

      var date = new Date();
      setTransactionDate(date);

      console.log(event);
    },
    [
      setTransaction,
      setInputTransactionId,
      setInputTransactionNo,
      setInputBeneficiaryAccount,
      setInputTransactionAmount,
      setTransactionDate,
    ]
  );

  const showBankTransactions = useCallback(
    (event) => {
      event.preventDefault();

      setTransaction(null);
    },
    [setTransaction]
  );

  const CustomPagination = useCallback(
    (props) => {
      const amount =
        dataItems.length > 0
          ? dataItems.reduce((amount, row) => amount + row.amount, 0)
          : 0;
      const currencyCode = dataItems.length > 0 ? dataItems[0].currencyCode : null;

      return (
        <CustomTablePagination {...props}>
          <CustomTableFooter>
            <FooterBlock compact={true}>Viso:</FooterBlock>
            <FooterBlock empty={true} couldGrow={true} />
            <FooterBlock align="right" width="250px">
              <span className="mobile-only mr-1">Suma: </span>
              {formatCurrency(amount, currencyCode)}
            </FooterBlock>
            <FooterBlock align="center" width="150px">
              Kiekis: {dataItems.length}
            </FooterBlock>
            <FooterBlock empty={true} />
            <FooterBlock empty={true} />
          </CustomTableFooter>
        </CustomTablePagination>
      );
    },
    [dataItems]
  );

  // chech if user
  if (!user || !user.subject) return;

  return (
    <div className="content">
      <div className="container">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-thumbs-up mr-1"></i> Gauti pavedimai
            </h3>

            <div className="card-tools">
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={showAddTransaction}
                >
                  Pridėti pavedimą
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            {!transaction && (
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Data nuo</label>

                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={endDate}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        locale="lt"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Data iki</label>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        locale="lt"
                      />
                    </div>
                  </div>
                  <ShowButtonBlock text="Rodyti" />
                </div>
              </form>
            )}

            {transaction && (
              <form onSubmit={onSubmitEdit}>
                <br />

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Pavedimo numeris</label>
                      <input
                        type="text"
                        className="form-control"
                        value={inputTransactionNo}
                        onInput={(e) => setInputTransactionNo(e.target.value)}
                      ></input>
                    </div>

                    <div className="form-group">
                      <label>Sąskaita</label>
                      <input
                        type="text"
                        className="form-control"
                        value={inputBeneficiaryAccount}
                        onInput={(e) => setInputBeneficiaryAccount(e.target.value)}
                      ></input>
                    </div>

                    <div className="form-group">
                      <label>Pavedimo data</label>
                      <DatePicker
                        selected={transactionDate}
                        onChange={(date) => setTransactionDate(date)}
                        selectsEnd
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        locale="lt"
                      />
                    </div>

                    <div className="form-group">
                      <label>Pavedimo suma EUR</label>
                      <input
                        type="text"
                        className="form-control"
                        value={inputTransactionAmount}
                        onInput={(e) => setInputTransactionAmount(e.target.value)}
                      ></input>
                    </div>

                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={showBankTransactions}
                    >
                      Atšaukti
                    </button>

                    <button type="submit" className="btn btn-success float-right">
                      Tęsti
                    </button>
                  </div>
                </div>
              </form>
            )}

            {dataItems && !transaction && (
              <DataTable
                columns={columns}
                data={dataItems}
                noDataComponent="Nėra duomenų"
                paginationComponent={CustomPagination}
                pagination
              />
            )}
          </div>

          <div className="card-footer clearfix">
            <Link to="/" className="btn btn-info">
              Atgal
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankTransactions;
