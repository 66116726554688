import React, { useState } from "react";
import { useSignOut, useUserContext } from "src/shared/hooks";
import Countdown from "react-countdown";
import Config from "../Config/Config";
import api from "../../api/helperPromise";
import TopBarProgress from "react-topbar-progress-indicator";
import { toast } from "react-toastify";
import { LoginButton } from "./LoginButton";

const LoginFormMidba = ({ childFormInfo, getSession }) => {
  TopBarProgress.config({
    barColors: {
      0: "#2ed876",
      0.8: "#24AC5E",
      "1.0": "#2ed876",
    },
    shadowBlur: 10,
  });
  const [topBarProgress, setTopBarProgress] = useState(false);

  // context
  const { mutateUser } = useUserContext();
  const { signOut } = useSignOut();
  //return;

  //states
  //const [authFlag, setAuthFlag] = useState(false);
  // 50001018865
  // +37060000666
  const [inputSmartId, setInputSmartId] = useState("");
  const [inputPhone, setInputPhone] = useState("");
  const [inputSmartIdInvalid, setInputSmartIdInvalid] = useState("");
  const [inputPhoneInvalid, setInputPhoneInvalid] = useState("");
  const [labelCode, setLabelCode] = useState("");
  const [loading, setLoading] = useState(false);

  const setAuthContext = (auth) => {
    const userMerge = { auth: auth, login: true };
    mutateUser(userMerge);
  };

  const signInStatus = (sessionId, accessToken) => {
    console.log("authAuthenticationStatus start");
    setTopBarProgress(true);

    api
      .authAuthenticationStatus(sessionId, accessToken)
      .finally(() => console.log("authAuthenticationStatus Promise is ready"))
      .then((data) => {
        console.log("authAuthenticationStatus data", data);
        toast.success("Sveiki " + data.authPersonData.firstName);

        if (data.authPersonData?.code) {
          setAuthContext(data.authPersonData);
        }

        setTopBarProgress(false);
        setLoading(false);
        childFormInfo("");
      })
      .catch((err) => {
        console.log("authAuthenticationStatus", err);

        if (err == Config.REST_RESULTCODE.WAITING) {
          console.log("authAuthenticationStatus setTimeout");
          setTimeout(function () {
            signInStatus(sessionId, accessToken);
          }, 2500);

          //} else if (Config.REST_RESULTCODE.REJECTED) {
        } else {
          if (err.value?.result?.messageText) toast.error(err.value?.result?.messageText);

          setLabelCode("");
          setTopBarProgress(false);
          setLoading(false);
          childFormInfo("");
        }
      });
  };

  const signIn = async () => {
    console.log("authAuthenticate start");
    setTopBarProgress(true);
    setLoading(true);

    // disable child tab
    childFormInfo("MIDBA");

    const authType = "DOKOBIT_MIDBA";
    const authData = {
      code: inputSmartId,
      phone: inputPhone,
      country: "LT",
    };
    const body = {
      authType: authType,
      authData: authData,
    };

    try {
      const session = await getSession();
      const data = await api.authAuthenticate(session.sessionId, body);
      console.log("authAuthenticate Promise is ready");
      console.log("authAuthenticate data", data);

      if (data.controlCode && data.accessToken) {
        toast.success("Kontrolinis kodas išsiųstas į jūsų įrenginį:" + data.controlCode);

        setLabelCode(data.controlCode);

        signInStatus(session.sessionId, data.accessToken);
      } else {
        setTopBarProgress(false);
        setLoading(false);
        childFormInfo("");

        toast.error("controlCode: " + data.controlCode);
        if (!data.controlCode) {
          signOut();
        }
      }
    } catch (err) {
      console.log("authAuthenticate", err);

      if (err.value?.result?.messageText) toast.error(err.value?.result?.messageText);

      if (err.result?.messageText) toast.error(err.result?.messageText);

      if (!err.value?.result?.messageText && !err.result?.messageText) {
        toast.error("Autentifikavimo klaida");
      }

      setTopBarProgress(false);
      setLoading(false);
      childFormInfo("");
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    let valudateFlag = true;

    // code test
    if (inputSmartId.length >= 11) {
      setInputSmartIdInvalid("");
    } else {
      valudateFlag = false;
      setInputSmartIdInvalid("is-invalid");
    }

    // phone test
    const validPhone = new RegExp(/(\+3706)([0-9]{7})/, "");

    if (validPhone.test(inputPhone) && inputPhone.length <= 12) {
      setInputPhoneInvalid("");
    } else {
      valudateFlag = false;

      setInputPhoneInvalid("is-invalid");
    }

    if (valudateFlag) signIn();
  };

  const handleChange = (event) => {
    let result = event.target.value;

    switch (event.target.id) {
      case "smartId":
        result = result.replace(/[^0-9]/gi, "");

        setInputSmartId(result);
        return;
      case "phone":
        result = result.replace(/[^+0-9]/gi, "");
        setInputPhone(result);

        setInputPhoneInvalid("");
        return;
      default:
        return;
    }
  };

  // countdown
  const Completionist = () => <span>(o_O)</span>;

  const rendererCountdown = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span className="btn btn-light btn-xl">
          {minutes}:{seconds}m
        </span>
      );
    }
  };

  return (
    <>
      {topBarProgress && <TopBarProgress />}

      <div className="card-body px-1 p-sm-3">
        <p className="login-box-msg">Užsiregistruoti su mobilioju parašu</p>

        <form onSubmit={onSubmit}>
          {labelCode ? (
            <div className="text-center">
              <p>Kontrolinis kodas išsiųstas į jūsų įrenginį</p>
              <h1 className="alert alert-light text-xl">{labelCode}</h1>
              <p>
                Jūsų kontrolinio kodo galiojimas baigsis už{" "}
                <Countdown date={Date.now() + 119000} renderer={rendererCountdown} />
              </p>
              <p>
                <small>
                  Jei aukščiau pavaizduotas kodas sutampa su kodu, kurį matote telefono
                  ekrane, įveskite savo sPIN1 kodą.
                </small>
              </p>
            </div>
          ) : (
            <>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className={`form-control ${inputSmartIdInvalid}`}
                  placeholder="Asmens kodas"
                  id="smartId"
                  value={inputSmartId}
                  onChange={handleChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>

                <span className="error invalid-feedback">
                  Neteisingai nurodytas asmens kodas
                </span>
              </div>

              <div className="input-group mb-3">
                <input
                  type="text"
                  className={`form-control ${inputPhoneInvalid}`}
                  placeholder="Telefono numeris"
                  id="phone"
                  value={inputPhone}
                  onChange={handleChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-phone"></span>
                  </div>
                </div>

                <span className="error invalid-feedback">
                  Neteisingai nurodytas telefono numeris. Pvz: +37061234567
                </span>
              </div>

              <div className="row d-flex justify-content-end">
                <LoginButton loading={loading} />
              </div>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default LoginFormMidba;
