import { AuthInfoResponse, AuthStatusResponse } from "src/shared/types/auth";
import { AuthRequest } from "src/shared/types/auth/requests";
import { AuthDataResponse } from "src/shared/types/auth/responses/AuthDataResponse";
import { ResponseWrapper } from "src/shared/types/ResponseWrapper";
import Config from "../components/Config/Config";

class apiAuth {
  authAuthenticationStatus = (
    sessionId: string,
    accessToken: string
  ): Promise<AuthStatusResponse> => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiAuthAuthenticationStatus.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
      };

      fetch(
        Config.REST_URL +
          Config.REST_API.apiAuthAuthenticationStatus.PATH +
          "?authSessionId=" +
          accessToken,
        requestOptions
      )
        .then((res) => {
          //console.warn(res);
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<AuthStatusResponse>): void => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            if (data?.value?.authStatus == Config.REST_RESULTCODE.WAITING) {
              reject(Config.REST_RESULTCODE.WAITING);
              return;
            }

            if (data?.value?.authStatus == Config.REST_RESULTCODE.REJECTED) {
              reject(Config.REST_RESULTCODE.REJECTED);
              return;
            }

            resolve(data.value);
            return;
          }

          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  authAuthenticate = (
    sessionId: string,
    body: AuthRequest
  ): Promise<AuthDataResponse> => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiAuthAuthenticate.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
        body: JSON.stringify(body),
      };

      fetch(Config.REST_URL + Config.REST_API.apiAuthAuthenticate.PATH, requestOptions)
        .then((res) => {
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }

          return res.json();
        })
        .then((data: ResponseWrapper<AuthDataResponse>): void => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  authAuthInfo = (sessionId: string): Promise<AuthInfoResponse> => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiAuthAuthInfo.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
      };

      fetch(Config.REST_URL + Config.REST_API.apiAuthAuthInfo.PATH, requestOptions)
        .then((res) => {
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<AuthInfoResponse>): void => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  authLogout = (sessionId: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiAuthLogout.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
      };

      fetch(Config.REST_URL + Config.REST_API.apiAuthLogout.PATH, requestOptions)
        .then((res) => {
          resolve("signOut");
        })
        .catch((err: unknown) => {
          reject(err);
        });
    });
  };
}

export default apiAuth;
