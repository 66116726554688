import React, { ReactElement } from "react";
import "./styles.css";

export const PagePlaceholder = (): ReactElement => {
  return (
    <div className="spinner-container">
      <div className="d-flex flex-row justify-content-center">
        <div className="spinner-grow text-success" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="spinner-grow text-success" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="spinner-grow text-success" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
};
