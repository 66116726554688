import React, { ReactElement, useCallback } from "react";
import { toast } from "react-toastify";

type TileNameProps = {
  children: string | ReactElement;
  hint?: string;
};

export const TileName = ({ children, hint }: TileNameProps): ReactElement => {
  const helpTipsToast = useCallback(() => {
    toast.info(hint);
  }, [hint]);

  return (
    <span className="info-box-text">
      {children}{" "}
      {hint ? (
        <button className="btn btn-info btn-xs float-sm-right" onClick={helpTipsToast}>
          &nbsp; ? &nbsp;
        </button>
      ) : null}
    </span>
  );
};
