import React, { useMemo, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { UserContext } from "src/shared/hooks";

import "./App.css";

// view components
import Cookies from "./components/Cookies/Cookies";
import Session from "./components/Session/Session";
import Subject from "./components/Subject/Subject";

import NavTop from "./components/NavTop/NavTop";
import Breadcrumb from "./components/Breadcrumb/Breadcrumb";
import Routes from "./components/Routes/Routes";

function App() {
  //const [value, setValue] = useContext(UserContext);
  const [user, setUser] = useState(null);
  const providerValue = useMemo(() => ({ user, setUser }), [user, setUser]);
  //console.log("User Data:", user);

  return (
    <Router>
      <Cookies />
      <UserContext.Provider value={providerValue}>
        <Session />

        <NavTop />
        <Breadcrumb />
        <Subject />

        <Routes />
      </UserContext.Provider>
    </Router>
  );
}

export default App;
