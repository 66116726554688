import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../api/helperPromise";
import { format } from "date-fns";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import lt from "date-fns/locale/lt";
import { toast } from "react-toastify";
import { useUserContext } from "src/shared/hooks";

import DataTable from "react-data-table-component";
import { ShowButtonBlock } from "../base/ShowButtonBlock";
import {
  CustomTablePagination,
  CustomTableFooter,
  FooterBlock,
} from "../base/CustomTablePagination";

registerLocale("lt", lt);

function Uploader() {
  // states
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const [dataItems, setDataItems] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  // context
  const { user } = useUserContext();

  const CustomPagination = useCallback(
    (props) => {
      const count =
        dataItems.length > 0
          ? dataItems.reduce((count, row) => count + row.transactionCount, 0)
          : 0;

      return (
        <CustomTablePagination {...props}>
          {
            <CustomTableFooter>
              <FooterBlock compact={true}>Viso:</FooterBlock>
              <FooterBlock empty={true} couldGrow={true} />
              <FooterBlock align="right" width="250px">
                <span className="mobile-only mr-1">Kiekis:</span> {count}
              </FooterBlock>
              <FooterBlock empty={true} width="400px" />
            </CustomTableFooter>
          }
        </CustomTablePagination>
      );
    },
    [dataItems]
  );

  // table
  const columns = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row.id,
        sortable: true,
        compact: true,
        minWidth: "2%",
        maxWidth: "4%",
      },
      {
        name: "Bankas",
        selector: (row) => row.name,
        sortable: true,
        compact: true,
      },
      {
        name: "Data",
        selector: (row) => row.date,
        sortable: true,
        maxWidth: "100px",
        compact: true,
      },
      {
        name: "Būklė",
        selector: (row) => row.processingStatus,
        sortable: true,
        compact: true,
        minWidth: "50px",
        maxWidth: "150px",
      },
      {
        name: "Transakcijų kiekis",
        selector: (row) => row.transactionCount,
        sortable: true,
        right: true,
        maxWidth: "150px",
      },
      {
        name: "Bylos pavadinimas",
        selector: (row) => row.fileName,
        sortable: true,
        maxWidth: "200px",
      },
      {
        name: "",
        button: true,
        selector: (row) => <ButtonInfo row={row} />,
        sortable: true,
        compact: true,
      },
      {
        name: "",
        button: true,
        selector: (row) => <ButtonDownload row={row} />,
        sortable: true,
        compact: true,
      },
    ],
    [] // eslint-disable-line
  );

  //return;
  if (!user) return;

  const sessionId = user?.session?.sessionId;

  const ButtonInfo = ({ row }) => (
    <Link
      className="btn btn-info btn-sm"
      to={`/AcquirerTransactions?_filename=${row.fileName}&_id=${row.documentId}&_date=${row.date}`}
      target="_blank"
    >
      Peržiūrėti
    </Link>
  );
  const ButtonDownload = ({ row }) => (
    <button
      className="btn btn-success btn-sm"
      onClick={downloadHandler}
      value={`${row.documentId}`}
    >
      Parsisiusti
    </button>
  );

  const downloadHandler = (event) => {
    const documentId = event.target.value;

    if (documentId) {
      fileDownload(documentId);
    }
  };

  const fileDownload = (documentId) => {
    console.log("fileDownload start");
    api
      .fileDownload(sessionId, documentId)
      .finally(() => console.log("fileDownload Promise is ready"))
      .then((data) => {
        console.log("fileDownload data", data);

        toast.success(data.file?.name);

        if (data.file?.data && data.file?.name && data.file?.type) {
          const documentBase64 = "data:text/plain;base64," + data.file.data;

          let a = document.createElement("a");
          a.href = documentBase64;
          a.download = data.file.name;
          a.click();

          //triggerBase64Download(documentBase64, data.file.name);
        }
      })
      .catch((err) => {
        console.warn("fileDownload", err);

        if (err.value?.result?.messageText) toast.error(err.value?.result?.messageText);
      });
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);

    setIsFilePicked(true);
  };

  const handleSubmission = () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("fileName", selectedFile.name);
    formData.append("documentType", "ACQ_TRN");

    //console.log(selectedFile)

    for (let obj of formData) {
      console.log(obj);
    }

    fileUpload(formData);
  };

  const fileUpload = (formData) => {
    console.log("fileUpload start");
    api
      .fileUpload(sessionId, formData)
      .finally(() => console.log("fileUpload Promise is ready"))
      .then((data) => {
        console.log("fileUpload data", data);

        setIsFilePicked(false);
        alert("File was uploaded. " + data.result.messageText);
      })
      .catch((err) => {
        console.error("fileUpload", err);

        if (err.value?.result?.messageText) toast.error(err.value?.result?.messageText);
      });
  };

  const documentList = () => {
    console.log("documentList start");

    var startDateFormated = format(startDate, "yyyy-MM-dd");
    var endDateFormated = format(endDate, "yyyy-MM-dd");

    const body = {
      dateFrom: startDateFormated,
      dateTo: endDateFormated,
    };

    api
      .documentDocumentList(sessionId, body)
      .finally(() => console.log("documentList Promise is ready"))
      .then((data) => {
        console.log("documentList data", data);

        var newData = [];

        data.documentList.map((item, i) => {
          //console.log(item, i)

          var date = new Date(item.date);

          const tempData = {
            id: i + 1,
            documentId: item.id,
            fileName: item.fileName,
            name: item.name,
            date: format(date, "yyyy-MM-dd"),
            processingStatus: item.processingStatus,
            transactionCount: item.transactionCount,
          };

          //console.log(tempData);
          newData.push(tempData);
        });

        setDataItems(newData);
      })
      .catch((err) => {
        console.log("documentList", err);
      });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    documentList();
  };

  return (
    <div className="content">
      <div className="container">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-credit-card mr-1"></i> Įkelti failą
            </h3>
          </div>
          <div className="card-body">
            <form id="uploadForm">
              <div className="row">
                <div className="col-md-6">
                  {isFilePicked ? (
                    <p>
                      Filename: <b>{selectedFile.name}</b>
                      <br />
                      Filetype: {selectedFile.type}
                      <br />
                      Size in bytes: {selectedFile.size}
                    </p>
                  ) : (
                    <p>Pasirinkite failą</p>
                  )}

                  <div className="form-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="inputFile"
                        name="inputFile"
                        onChange={changeHandler}
                      />
                      <label className="custom-file-label" htmlFor="inputFile">
                        {isFilePicked ? selectedFile.name : "Pasirinkti failą"}
                      </label>
                    </div>

                    {isFilePicked && (
                      <React.Fragment>
                        <br />
                        <br />
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={handleSubmission}
                        >
                          Upload
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-thumbs-up mr-1"></i> Banko ataskaita
            </h3>
          </div>
          <div className="card-body">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Data nuo</label>

                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      maxDate={endDate}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      locale="lt"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Data iki</label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      locale="lt"
                    />
                  </div>
                </div>
                <ShowButtonBlock text="Rodyti" />
              </div>
            </form>

            {dataItems && (
              <DataTable
                columns={columns}
                data={dataItems}
                noDataComponent="Nėra duomenų"
                paginationComponent={CustomPagination}
                pagination
              />
            )}
          </div>

          <div className="card-footer clearfix">
            <Link to="/" className="btn btn-info">
              Atgal
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Uploader;
