import { Routes, Route } from "react-router-dom";

import React from "react";
import { useUserContext } from "src/shared/hooks";
import Config from "../Config/Config";

import Layout from "src/pages/Layout";
import NoPage from "src/pages/NoPage";
import About from "src/pages/About";
import Contact from "src/pages/Contact";
import Faq from "src/pages/Faq";
import CookiesPolicy from "src/pages/CookiesPolicy";

import LoginForm from "../LoginForm/LoginForm";
import Dashboard from "../Dashboard/Dashboard";
import Profile from "../Profile/Profile";
import Cards from "../Cards/Cards";
import Stats from "../Stats/Stats";
import PdfViewer from "../Pdfviewer/Pdfviewer";
import Uploader from "../Uploader/Uploader";
import SubjectStats from "../SubjectStats/SubjectStats";
import AcquirerTransactions from "../AcquirerTransactions/AcquirerTransactions";
import PaymentDocumentList from "../PaymentDocumentList/PaymentDocumentList";
import BankTransactions from "../BankTransactions/BankTransactions";
import paths from "src/shared/constants/appRoutes";
import { PagePlaceholder } from "../PagePlaceholder";

const restrictedPagePaths = [
  paths.subjectStats,
  paths.uploader,
  paths.acquirerTransactions,
  paths.paymentDocumentList,
  paths.bankTransactions,
  paths.profile,
];

export default function AppRoutes() {
  // context
  const { user } = useUserContext();

  //console.warn(user.subject?.subject)

  return (
    <Routes>
      <Route path={paths.default} element={<Layout />}>
        {user && user.login ? (
          user && user.subject?.subject?.privateEntity ? (
            <React.Fragment>
              <Route index element={<Dashboard />} />
              <Route path={paths.profile} element={<Profile />} />
            </React.Fragment>
          ) : (
            <Route index element={<Profile />} />
          )
        ) : (
          <Route index element={<LoginForm />} />
        )}
        <Route path={paths.about} element={<About />} />
        <Route path={paths.contact} element={<Contact />} />
        <Route path={paths.faq} element={<Faq />} />
        <Route path="*" element={<NoPage />} />

        <Route path={paths.cards} element={<Cards />} />
        <Route path={paths.stats} element={<Stats />} />
        <Route path={paths.pdf} element={<PdfViewer />} />
        <Route path={paths.cookiesPolicy} element={<CookiesPolicy />} />
        {(user?.auth?.role == Config.ROLE.TIPSOPERATOR ||
          user?.auth?.role == Config.ROLE.TIPSACCOUNTANT) && (
          <Route path={paths.subjectStats} element={<SubjectStats />} />
        )}
        {user?.auth?.role == Config.ROLE.TIPSACCOUNTANT && (
          <React.Fragment>
            <Route path={paths.uploader} element={<Uploader />} />
            <Route path={paths.acquirerTransactions} element={<AcquirerTransactions />} />
            <Route path={paths.paymentDocumentList} element={<PaymentDocumentList />} />
            <Route path={paths.bankTransactions} element={<BankTransactions />} />
          </React.Fragment>
        )}

        {(!user || !user.login) &&
          restrictedPagePaths.map((path) => (
            <Route key={path} path={path} element={<PagePlaceholder />} />
          ))}
      </Route>
    </Routes>
  );
}
