import { ResponseWrapper } from "src/shared/types/ResponseWrapper";
import {
  SubjectOperationResponse,
  SubjectRequest,
  SubjectResponse,
} from "src/shared/types/subjects";
import Config from "../components/Config/Config";

class apiSubject {
  subjectGetSubject = (
    sessionId: string,
    code: string,
    type: string
  ): Promise<SubjectResponse> => {
    if (!type) type = "CODE";

    return new Promise<SubjectResponse>((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiSubjectGetSubject.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
      };

      fetch(
        Config.REST_URL +
          Config.REST_API.apiSubjectGetSubject.PATH +
          "?identType=" +
          type +
          "&identCode=" +
          code,
        requestOptions
      )
        .then((res) => {
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<SubjectResponse>) => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  subjectNewSubject = (
    sessionId: string,
    body: SubjectRequest
  ): Promise<SubjectOperationResponse> => {
    return new Promise<SubjectOperationResponse>((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiSubjectNewSubject.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
        body: JSON.stringify(body),
      };

      fetch(Config.REST_URL + Config.REST_API.apiSubjectNewSubject.PATH, requestOptions)
        .then((res) => {
          //console.warn(res);
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<SubjectOperationResponse>): void => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  subjectUpdateSubject = (
    sessionId: string,
    body: SubjectRequest
  ): Promise<SubjectOperationResponse> => {
    return new Promise<SubjectOperationResponse>((resolve, reject) => {
      const requestOptions = {
        method: Config.REST_API.apiSubjectUpdateSubject.METHOD,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Session " + sessionId,
        },
        body: JSON.stringify(body),
      };

      fetch(
        Config.REST_URL + Config.REST_API.apiSubjectUpdateSubject.PATH,
        requestOptions
      )
        .then((res) => {
          //console.warn(res);
          if (!res.ok) {
            throw Error(
              Config.ERROR.RESULT_NOT_OK + " > " + res.status + " > " + res.statusText
            );
          }
          return res.json();
        })
        .then((data: ResponseWrapper<SubjectOperationResponse>): void => {
          if (data?.value?.result?.resultCode == Config.REST_RESULTCODE.OK) {
            resolve(data.value);
            return;
          }
          reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export default apiSubject;
