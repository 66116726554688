import React from "react";

function Faq() {
  const faq = [
    {
      question: "Kaip gauti <strong>my tips</strong> kortelę?",
      answer:
        'Norint naudotis <strong>my tips</strong> arbatpinigių paslauga, darbuotojas iš prekybininko nemokamai gauna Mokėjimo įstaigos išduotą <strong>my tips</strong> kortelę. Daugiau informacijos <a href="' +
        process.env.PUBLIC_URL +
        '/share/BENDROSIOS_SALYGOS.pdf" target="_blank">Mokėjimų pervedimų paslaugų bendrosios sąlygos</a>.',
    },

    {
      question: "Kaip užregistruoti <strong>my tips</strong> kortelę?",
      answer:
        'Gautą <strong>my tips</strong> kortelę būtina užregistruoti savitarnos svetainėje <a href="https://tips.ashburn.eu" target="_black">https://tips.ashburn.eu</a> pasirašant sutartį su mokėjimo įstaiga. Daugiau informacijos <a href="' +
        process.env.PUBLIC_URL +
        '/share/BENDROSIOS_SALYGOS.pdf" target="_blank">Mokėjimų pervedimų paslaugų bendrosios sąlygos</a>.',
    },

    {
      question: "Kaip naudoti <strong>my tips</strong> kortelę aptarnavimo metu?",
      answer:
        'Norint gauti arbatpinigius, Darbuotojas prieš priimant kliento atsiskaitymą už paslaugas turi būtinai identifikuoti save, perbraukdamas <strong>my tips</strong> kortele EKS terminale. Arbatpinigiai bus priskirti ir pervesti darbuotojui, kuris sėkmingai užregistravo <strong>my tips</strong> kortelę, pasirašė sutartį mokėjimo įstaigos savitarnos svetainėje <a href="https://tips.ashburn.eu" target="_black">https://tips.ashburn.eu</a> ir teisingai nurodė savo duomenys. Daugiau informacijos <a href="' +
        process.env.PUBLIC_URL +
        '/share/BENDROSIOS_SALYGOS.pdf" target="_blank">Mokėjimų pervedimų paslaugų bendrosios sąlygos</a>.',
    },

    {
      question: "Pamečiau <strong>my tips</strong> kortelę - ką daryt?",
      answer:
        'Pametus ar kitokiu būdu praradus <strong>my tips</strong> kortelę, darbuotojas turi nedelsdamas, per 24 val. užblokuoti prarastą <strong>my tips</strong> kortelę savitarnos svetainėje <a href="https://tips.ashburn.eu" target="_black">https://tips.ashburn.eu</a>. Po <strong>my tips</strong> kortelės blokavimo, darbuotojas turi teisę kreiptis į prekybininką dėl naujos <strong>my tips</strong> kortelės gavimo, kurią būtina užregistruoti pasirašant Sutartį savitarnos svetainėje <a href="https://tips.ashburn.eu" target="_black">https://tips.ashburn.eu</a>',
    },

    {
      question: "Kokie mokesčiai taikomi arbatpinigiams?",
      answer:
        'Iš arbatpinigių sumos atskaitomas tuo metu galiojančio dydžio Lietuvos Respublikos gyventojų pajamų mokestis (GPM) ir kiti su <strong>my tips</strong> paslauga susiję mokesčiai pagal Sutarties <a href="' +
        process.env.PUBLIC_URL +
        '/share/KAINORASTIS.pdf" target="_blank">kainoraštį</a>. ',
    },

    {
      question: "Kada darbuotojai gauna arbatpinigius į asmeninę banko sąskaitą?",
      answer:
        "Banko arbatpinigių pervedimas mokėjimo įstaigai trunka iki 4 darbo dienų nuo klientų arbatpinigių palikimo momento. Mokėjimo įstaiga, gavusi arbatpinigius į surenkamąja sąskaitą, perveda arbatpinigius darbuotojui į jo asmeninę banko sąskaitą per 24 valandas nuo arbatpinigių įskaitymo į surenkamąją sąskaitą (darbo dienomis arba pirmąją darbo dieną po poilsio/šventinių dienų).",
    },

    {
      question: "Kur pateikiama surinktų arbatpinigių suvestinė?",
      answer:
        'Gautų ir pervestų arbatpinigių suvestinė pateikiama savitarnos svetainėje, darbuotojo  paskyroje <a href="https://tips.ashburn.eu" target="_black">https://tips.ashburn.eu</a>.',
    },

    {
      question: "Ar darbuotojas gauna kvitą su arbatpinigių suma po aptarnavimo?",
      answer:
        "Taip, darbuotojas gauna atskirą kvitą su klientų paliktų arbatpinigių suma po kiekvieno aptarnavimo.",
    },

    {
      question: "Ar klientas gauna kvitą su arbatpinigių suma po aptarnavimo?",
      answer:
        "Taip, po aptarnavimo klientas gauna kvitą su atsiskaitymo ir paliktų arbatpinigių sumomis.",
    },

    {
      question:
        "Ar visose paslaugų tiekimo vietose galima naudoti <strong>my tips</strong> kortelę?",
      answer:
        "Darbuotojas gali naudoti <strong>my tips</strong> kortelę daugiau nei vienoje skirtingų Prekybininkų paslaugų tiekimo vietose, jeigu jose yra naudojami mokėjimo kortelių skaitytuvai su aktyvuota <strong>my tips</strong> arbatpinigių surinkimo paslauga.",
    },
  ];

  return (
    <div className="content">
      <div className="container">
        <h2>D.U.K.</h2>

        <div className="col-12" id="accordion">
          {faq.map(({ question, answer }, index) => {
            return (
              <div className="card card-outline" key={index}>
                <a
                  className="d-block w-100"
                  data-toggle="collapse"
                  href={`#collapse${index}`}
                  aria-expanded="false"
                >
                  <div className="card-header">
                    <h4 className="card-title w-100">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: question,
                        }}
                      />
                    </h4>
                  </div>
                </a>
                <div
                  id={`collapse${index}`}
                  className="collapse"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: answer,
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Faq;
